/*1rem=10px*/
/*benefit page*/
.box-type-1 {
  background: $grayishWhite;
  line-height: 3.1rem;
  text-align: justify;
  border: 0.1rem solid $bluishWhite;
  border-radius: 1rem;
}

/*homepage*/
.box-bg-white {
  background: $white;
  box-shadow: 0 0.4rem 1rem $coinGray;
  padding-top: 1.5rem;
}

/*my scheme-home page*/
.box-bg-white-2 {
  @include flexbox(normal, center);
  padding: 1.9rem;
  background: $white;
  box-shadow: 0 0.4rem 1rem $metalGray;
  border-radius: 0.8rem;
}
/*ineligible screen*/
.box-bg-white-3 {
  background: $white;
  box-shadow: 0 0 0.2rem $ravenBlack;
  border-radius: 0.4rem;
  padding: 1.9rem;
  min-width: 80vw;
  .box-inner-bg-white-3 {
    max-height: 24.6rem;
  }
}

/*create profile-home page*/
.box-bg-white-4 {
  @include flexbox(normal, center);
  padding: 0.91665rem 0;
  margin: 0 2.3vw;
  margin-bottom: 2rem;
  background-color: $white;
  box-shadow: 0 0.4rem 1rem $coinGray;
  border-radius: 0.8rem;
}

/*profile page-user profile*/
.profile{
  // background:url("../scssImgs/wave.png") contain no-repeat  ;
  background-image: url("../scssImgs/wave.png");
  background-repeat:no-repeat;
  background-size:contain ;
}
.profile-about-user-container {
  width: 90vw;
  background: $white;
  box-shadow: 0 .2rem .5rem $black;
  border-radius: 1rem;
 
}
.profile-about-user {
  padding: 2rem 3.1rem 1.5rem 3.1rem;
}

/*change profile screen*/
.profile-box{
  @include flexbox(default,center);
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  border-bottom: .1rem solid $mercury;
}

/*survey*/
.survey-main-box {
  width: 90vw;
  height: 65vh;
  background:$transparent2;
  border: .2rem solid $berryBlue;
  border-radius: 1rem;
}
.survey-box {
  border: 0.1rem solid $lightGray;
  border-radius: 0.5rem;
  padding: 1.7rem 0 1.7rem 0.8rem;
  background-color: $white;
}
.survey-box-active{
  border: 0.08rem solid $productColor;
  background-color: $aliceBlue;
  span{
    color: $productColor;
  }
}
.survey-inner-box{
  padding: 2.5rem 2.9rem 0 2.2rem;
}

.review-paragraph-main-box {
  width: 90vw;
  background:$white;
  border: .2rem solid $lightGray;
  border-radius: 1rem;
}

.review-paragraph-product-name-box {
  margin-top: 10rem;
  position: fixed;
  max-width: 100vw;
  height: 5.2rem;
  display: flex;
  align-items: center;
  padding: 0 1.2rem;
  background-color: $paleWhite;
  left: 0;
  right: 0;
}

.review-application-mb-1 {
  margin-bottom: 40vh;
}

.margin-t-20{
  margin-top: 20rem;
}

.survey-main-box-awarness{
  width: 90% !important;
  height: 65vh;
  background:$transparent2;
  border: .2rem solid $berryBlue;
  border-radius: 1rem;
  margin-left: 4%;
}