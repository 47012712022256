/*1rem=10px*/
// Benefit page
.list-type-2 {
  padding: 0.8rem 1rem;
  li {
    @include font($font-xs, $font-weight-medium, $secondary-font);
    background: url(../scssImgs/lists/tick-blue.svg) no-repeat 0 1.2rem transparent;
    list-style-type: none;
    padding: 0 0 0.1rem 2.4rem;
    vertical-align: middle;
    li {
      font-size: $font-xxxs;
      padding-bottom: 0.3rem;
      padding-right: 2.2rem;
      line-height: 1.6rem;
      background: url(../scssImgs/lists/tick-green.svg) no-repeat 0 0.5rem transparent;
    }
  }
}

/*know more page*/
.list-type-1{
  @extend .list-type-2;
  padding:0 0 0.9rem 0;
  margin:1rem 0 0 3.71rem; 
  li {
    text-align: justify;
    background: url(../scssImgs/lists/tick-green.svg) no-repeat 0 0.5rem transparent;
    padding: 0 0 1.2rem 2.4rem;
    font-family: $primary-font;
    font-weight: $font-weight-regular;
  }
}
/*Job Card*/
.card-type-4-footer-list {
  padding: 1.5rem;
  li:nth-of-type(1) {
    list-style-type: none;
    padding: 0 0 0.8rem 2.4rem;
    vertical-align: middle;
  }
  li:nth-of-type(2) {
    @extend li,:nth-of-type(1);
  }
}

/*ineligible screen*/
.list-type-3 {
  padding-left: 1rem;
  li {
    @include alignment(2.2rem, 0.05rem);
    @include font($font-md, $font-weight-medium, $secondary-font);
    list-style-position: inside;
    color: $porpoiseGray;
    ol {
      margin-bottom: 0.5rem;
      padding-inline-start: 1.9rem;
      li {
        @include font($font-md, $font-weight-regular);
        color: $porpoiseGray;
      }
    }
  }
}





