/*1rem=10px*/
/*location*/
.scrollbar-3 {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $paleWhite;
    border-radius: 0.8rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $productColor;
    border-radius: 0.8rem;
  }
}

// ineligible schemes-to be edited

.box-inner-bg-white-3 {
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $paleWhite;
    border-radius: 5.44416px;
  }
  &::-webkit-scrollbar-thumb {
    background: $productColor;
    border-radius: 0.544416rem;
  }
}

// popup-1 
.popup-1-content{
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 60vh;
  }
  &::-webkit-scrollbar-track {
    background-color: $paleWhite;
    border-radius: 5.44416px;
  }
  &::-webkit-scrollbar-thumb {
    background: $productColor;
    border-radius: 0.544416rem;
  }
}

//faq & recommended schemes
.scrollbar-2 {
  @extend .scrollbar-3;
  max-height: calc(100vh - 5.1rem);
  &::-webkit-scrollbar-track {
    margin-top: 2.2rem;
  }
}

//myschemes-scrollbar
//schemes,sevices scrollbar
.scrollbar-1 {
  @extend .scrollbar-2;
  max-height: calc(100vh - 5.9rem);
}

//myschemes-scrollbar
.scrollbar-7{
  @extend .scrollbar-1;
  &::-webkit-scrollbar-track {
    margin-top: 9rem;
  }
}

//change profile-scrollbar
.scrollbar-8{
  @extend .scrollbar-3;
  max-height: 46vh;
  margin-right: 4vw;
}
@media (max-height:647px) {
  .scrollbar-8{
    max-height:38vh;
  }
}


//category page
//notification
.scrollbar-4 {
  @extend .scrollbar-2;
  &::-webkit-scrollbar-track {
    margin-top: 1.5rem;
  }
}
//general survey page
.scrollbar-5{
  @extend .scrollbar-3;
  max-height: calc(65vh - 11.9rem) ;
  &::-webkit-scrollbar-track {
    margin-left: 1.5rem;
  }
}


//category survey
.scrollbar-6{
  @extend .scrollbar-5;
  max-height: calc(65vh - 12.2557rem) ;
}

//tooltip
.scrollbar-9{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 37vh;
  scroll-margin: 10px;
  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color:$grandmaGray ;
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $productColor2;
    border-radius: 0.5rem;
  }
}

.scrollbar-10 {
  @extend .scrollbar-2;
  max-height: calc(100vh - 7rem);
  &::-webkit-scrollbar-track {
    margin-top: 0rem;
  }
}



