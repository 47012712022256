/*1rem=10px*/
/*default size of browser will be 62.5%=10px=1rem*/
@media (max-width: 599.99px) and (min-width: 320px) {
  html {
    font-size: 62.5%;
  }
}
@media (max-width: 768px) and (min-width: 600px) {
  html {
    font-size: 72.5%;
  }
}


// introduction page
@media (max-height: 635px) {
  .slide-img-container {
    height: 53vh;
  }
  .slide-img-1 {
    height: 53vh;
  }
  .slide-img-2 {
    height: 148px;
    width: 148px;
    top: 4.4rem;
  }
  .slide-img-container p {
    top: 20.4rem;
  }
  .logo {
    width: 144.18px;
    height: 59.94px;
    margin-right: 10px;
  }
}

/*slider-responsive*/
@media (max-width:340px) and (min-width:320px){
  .card-type-1{
    width:6.1rem; 
  }
}
@media (max-width:335px) and (min-width:320px) {
  .card-type-3,.card-type-4{
    width: 28rem;
  }
  .card-type-2{
    width:12.9rem ;
  }
}
@media (min-width:430px) {
  .three-half{
    .slick-slide>div{
      padding: 0 1.4rem 0 0;
    }
  }
}
@media (min-width:516px) {
  .three-half{
    .slick-slide>div{
      padding: 0 2.2rem 0 0;
    }
  }
}
@media (min-width:545px) {
  .three-half{
    .slick-slide>div{
      padding: 0 1.6rem 0 0;
    }
  }
}
@media (min-width:599.99px) {
  .three-half{
    .slick-slide>div{
      padding: 0 2.2rem 0 0;
    }
  }
}
@media (min-width:629px) {
  .three-half{
    .slick-slide>div{
      padding: 0 1.5rem 0 0;
    }
  }
}
@media (min-width:720px) {
  .three-half{
    .slick-slide>div{
      padding: 0 2.2rem 0 0;
    }
  }
}
@media (min-width:492px){
  .two-half{
    .slick-slide>div{
      padding: 0 1.2rem 0 0;
    }
  }
}
@media (min-width:739px){
  .two-half{
    .slick-slide>div{
      padding: 0 2.3rem 0 0;
    }
  }
}


/*category-page-responsive*/
@media (max-width: 500px) and (min-width: 410px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) and (min-width: 500px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
/*location page-responsive*/
@media (max-width: 374.99px) and (min-width: 320px) {
  .grid-2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 498.99px) and (min-width: 375px) {
  .grid-2 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 721.99px) and (min-width: 499px) {
  .grid-2 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) and (min-width: 722px) {
  .grid-2 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-height:813px) {
  .grid-2 {
    max-height: 35vh;
  }
}
@media (max-height:748px) {
  .grid-2 {
    max-height: 44vh;
  }
}
@media (max-height:700px) {
  .grid-2 {
    max-height: 25vh;
  }
  .wrapper-button-2{
    padding:1vh 0 6vh 0;
  }
}
@media (max-height:643px) {
  .grid-2 {
    max-height: 30vh;
  }
}
@media (max-height:617px) {
  .grid-2 {
    max-height: 18vh;
  }
}
@media (max-height:568px) {
  .grid-2 {
    max-height: 18vh;
  }
}
// language page-responsive
@media (max-width: 499.99px) and (min-width: 320px) {
  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 768px) and (min-width: 500px) {
  .grid-3 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 364px) and (min-width: 330px) {
  .card-type-6 {
    min-width: 9.3rem;
  }
}
@media (max-width: 329.99px) and (min-width: 320px) {
  .card-type-6 {
    min-width: 8.9rem;
  }
}

//ineligible page
@media (max-height: 650px) {
  .center-container-2 img {
    height: 12.1rem;
    width: 12.1rem;
  }
}
//language & location popup
@media (min-width: 390px) and (max-width: 461px) {
  .fixed-1 {
    right: 2.5rem;
  }
}
@media (min-width: 461px) and (max-width: 524px) {
  .fixed-1 {
    right: 3.7rem;
  }
}
@media (min-width: 524px) and (max-width: 673px) {
  .fixed-1 {
    right: 4.7rem;
  }
}
@media (min-width: 673px) and (max-width: 768px) {
  .fixed-1 {
    right: 5.7rem;
  }
}
/*survey page*/
@media (max-height: 755px) {
  .survey-main-box {
    height: 58vh;
  }
  .scrollbar-5 {
    max-height: calc(58vh - 11.9rem);
  }
  .scrollbar-6 {
    max-height: calc(58vh - 12.2557rem);
  }
}
@media (max-height: 640px) {
  .survey-main-box {
    height: 47vh;
  }
  .scrollbar-5 {
    max-height: calc(47vh - 11.9rem);
  }
  .scrollbar-6 {
    max-height: calc(47vh - 12.2557rem);
  }
}

/*profile screen button*/
@media (max-width: 357px) {
  .add-profile {
    width: 22.5rem;
  }
}

/*terms & conditions popup*/
@media (max-height: 697px) {
  .popup-1-content {
    height: 55vh;
  }
}

/*create profile-button wrapper*/
@media (max-height:493px) {
  .wrapper-button-7{
    position: relative;
  }
}

@media(max-width:"366px") {
  .popup-doc-prev-content{
   overflow: hidden !important;
  } 
}

