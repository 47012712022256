@import "color";
//colors of cards
.color-green {
  .card-type-1 {
    background: $teaGreen;
    box-shadow: 0 0.4rem 1rem $sheenGreen;
    .card-type-1-content h3,
    p {
      color: $forestGreen;
    }
    .card-type-1-logo {
      background: $greenLizard;
    }
  }
  //same color properties get applied to card-type-2 & card-type-4 as its getting extended in card.scss
  .card-type-3 {
    @include boxshadow-opacity($mulberry, 0.15);
    border: 0.1rem solid $mulberry;
    border-bottom: 0.4rem solid $mulberry;
    .card-type-3-header {
      background: $paleLavender;
    }
    .card-type-3-logo {
      background-color: $mulberry;
    }
    .card-type-3-tag {
      color: $mulberry;
      border: 0.1rem solid $mulberry;
    }
    .traingle-2 {
      border-left: 0.8rem solid $mulberry;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($mulberry, 0.1);
      color: $mulberry;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-green.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-green.svg) no-repeat 0 0
        transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $mulberry;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($mulberry, 0.15);
    img{
      filter: brightness(0) saturate(100%) invert(73%) sepia(62%) saturate(370%) hue-rotate(38deg) brightness(89%) contrast(92%);
    }
  }
}
.color-orange {
  .card-type-1 {
    background: $provincialPink;
    box-shadow: 0 0.4rem 1rem $clayOrange;
    .card-type-1-content h3,
    p {
      color: $rustOrange;
    }
    .card-type-1-logo {
      background: $carrotOrange;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($darkOrchid, 0.15);
    border: 0.1rem solid $darkOrchid;
    border-bottom: 0.4rem solid $darkOrchid;
    .card-type-3-header {
      background: $apricot;
    }
    .card-type-3-logo {
      background-color: $darkOrchid;
    }
    .card-type-3-tag {
      color: $darkOrchid;
      border: 0.1rem solid $darkOrchid;
    }
    .traingle-2 {
      border-left: 0.8rem solid $darkOrchid;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($darkOrchid, 0.1);
      color: $darkOrchid;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-orange.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-orange.svg) no-repeat 0 0
        transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $darkOrchid;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($darkOrchid, 0.15);
    img{
      filter: brightness(0) saturate(100%) invert(51%) sepia(94%) saturate(356%) hue-rotate(356deg) brightness(98%) contrast(94%) ;
    }
  }
}

.color-lightblue {
  .card-type-1 {
    background: $celesteBlue;
    box-shadow: 0 0.4rem 1rem $darkBlue;
    .card-type-1-content h3,
    p {
      color: $sapphireBlue;
    }
    .card-type-1-logo {
      background: $cyanBlue;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($brilliantAzure, 0.15);
    border: 0.1rem solid $brilliantAzure;
    border-bottom: 0.4rem solid $brilliantAzure;
    .card-type-3-header {
      background: $veryLightBlue;
    }
    .card-type-3-logo {
      background-color: $brilliantAzure;
    }
    .card-type-3-tag {
      color: $cyanBlue;
      border: 0.1rem solid $brilliantAzure;
    }
    .traingle-2 {
      border-left: 0.8rem solid $brilliantAzure;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($brilliantAzure, 0.1);
      color: $brilliantAzure;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-lightblue.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-lightblue.svg) no-repeat 0 0
        transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $brilliantAzure;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($brilliantAzure, 0.15);
    img {
      filter: brightness(0) saturate(100%) invert(74%) sepia(67%) saturate(6188%) hue-rotate(188deg) brightness(106%) contrast(94%) ;
    }
  }
}

.color-purple {
  .card-type-1 {
    background: $magnolia;
    box-shadow: 0 0.4rem 1rem $deepPurple;
    .card-type-1-content h3,
    p {
      color: $iris;
    }
    .card-type-1-logo {
      background: $mauve;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($mauve, 0.15);
    border: 0.1rem solid $mauve;
    border-bottom: 0.4rem solid $mauve;
    .card-type-3-header {
      background: $lavender;
    }
    .card-type-3-logo {
      background-color: $mauve;
    }
    .card-type-3-tag {
      color: $mauve;
      border: 0.1rem solid $mauve;
    }
    .traingle-2 {
      border-left: 0.8rem solid $mauve;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($mauve, 0.1);
      color: $mauve;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-purple.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-purple.svg) no-repeat 0 0
        transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $mauve;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($mauve, 0.15);
    img {
      filter: brightness(0) saturate(100%) invert(77%) sepia(28%) saturate(6109%) hue-rotate(206deg) brightness(100%) contrast(96%);
    }
  }
}

.color-pink {
  .card-type-1 {
    background: $blushPink;
    box-shadow: 0 0.4rem 1rem $darkPink;
    .card-type-1-content h3,
    p {
      color: $magenta;
    }
    .card-type-1-logo {
      background: $balletPink;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($pink, 0.15);
    border: 0.1rem solid $pink;
    border-bottom: 0.4rem solid $pink;
    .card-type-3-header {
      background: $lightPink;
    }
    .card-type-3-logo {
      background-color: $pink;
    }
    .card-type-3-tag {
      color: $pink;
      border: 0.1rem solid $pink;
    }
    .traingle-2 {
      border-left: 0.8rem solid $pink;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($pink, 0.1);
      color: $pink;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-pink.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-pink.svg) no-repeat 0 0 transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $pink;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($pink, 0.15);
    img {
      filter: brightness(0) saturate(100%) invert(99%) sepia(100%) saturate(1722%) hue-rotate(284deg) brightness(99%) contrast(87%);
    }
  }
}

.color-brown {
  .card-type-1 {
    background: $eggShell;
    box-shadow: 0 0.4rem 1rem $brown;
    .card-type-1-content h3,
    p {
      color: $mustard;
    }
    .card-type-1-logo {
      background: $honeyCrisp;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($honeyCrisp, 0.15);
    border: 0.1rem solid $honeyCrisp;
    border-bottom: 0.4rem solid $honeyCrisp;
    .card-type-3-header {
      background: $barleywhite;
    }
    .card-type-3-logo {
      background-color: $honeyCrisp;
    }
    .card-type-3-tag {
      color: $honeyCrisp;
      border: 0.1rem solid $honeyCrisp;
    }
    .traingle-2 {
      border-left: 0.8rem solid $honeyCrisp;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($honeyCrisp, 0.1);
      color: $honeyCrisp;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-brown.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-brown.svg) no-repeat 0 0
        transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $honeyCrisp;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($honeyCrisp, 0.15);
    img {
      filter: brightness(0) saturate(100%) invert(73%) sepia(26%) saturate(592%) hue-rotate(10deg) brightness(92%) contrast(82%);
    }
  }
}

.color-red {
  .card-type-1 {
    background: $amour;
    box-shadow: 0 0.4rem 1rem $burgundy;
    .card-type-1-content h3,
    p {
      color: $rufous;
    }
    .card-type-1-logo {
      background: $fuchsiaBlush;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($fuchsiaBlush, 0.15);
    border: 0.1rem solid $fuchsiaBlush;
    border-bottom: 0.4rem solid $fuchsiaBlush;
    .card-type-3-header {
      background: $opalWhite;
    }
    .card-type-3-logo {
      background-color: $fuchsiaBlush;
    }
    .card-type-3-tag {
      color: $fuchsiaBlush;
      border: 0.1rem solid $fuchsiaBlush;
    }
    .traingle-2 {
      border-left: 0.8rem solid $fuchsiaBlush;
    }
  }
  .card-type-4 {
    .card-type-4-header span {
      @include background-opacity($fuchsiaBlush, 0.1);
      color: $fuchsiaBlush;
    }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-red.svg) no-repeat 0 0 transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-red.svg) no-repeat 0 0 transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $fuchsiaBlush;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($fuchsiaBlush, 0.15);
    img {
      filter: brightness(0) saturate(100%) invert(63%) sepia(40%) saturate(552%) hue-rotate(308deg) brightness(95%) contrast(90%);
    }
  }
}

.color-darkblue {
  .card-type-1 {
    background: $paleBlue;
    box-shadow: 0 0.4rem 1rem $denimBlue;
    .card-type-1-content h3,
    p {
      color: $egyptianBlue;
    }
    .card-type-1-logo {
      background: $cornFlowerBlue;
    }
  }
  .card-type-3 {
    @include boxshadow-opacity($cornFlowerBlue, 0.15);
    border: 0.1rem solid $cornFlowerBlue;
    border-bottom: 0.4rem solid $cornFlowerBlue;
    .card-type-3-header {
      background:$lightCyan;
    }
    .card-type-3-logo {
      background-color: $cornFlowerBlue;
    }
    .card-type-3-tag {
      color: $cornFlowerBlue;
      border: 0.1rem solid $cornFlowerBlue;
    }
    .traingle-2 {
      border-left: 0.8rem solid $cornFlowerBlue;
    }
    }
    .card-type-4 {
      .card-type-4-header span {
        @include background-opacity($cornFlowerBlue, 0.1);
        color: $cornFlowerBlue;
      }
  }
  .card-type-4-footer-list {
    li:nth-of-type(1) {
      background: url(../scssImgs/lists/rupee-darkblue.svg) no-repeat 0 0
        transparent;
    }
    li:nth-of-type(2) {
      background: url(../scssImgs/lists/time-darkblue.svg) no-repeat 0 0
        transparent;
    }
  }
  .card-type-7 {
    border: 0.5px solid $cornFlowerBlue;
  }
  .card-type-7-logo {
    @include logoshadow-opacity($cornFlowerBlue, 0.15);
    img {
      filter: brightness(0) saturate(100%) invert(69%) sepia(4%) saturate(4449%) hue-rotate(189deg) brightness(96%) contrast(96%);
    }
  }
}
