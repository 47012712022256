

/*1rem=10px*/
$utilities: (
  "margin-top": (
    "prefix": "margin-t",
    "values": (
      "1": 0.8rem,
      "1-1": 1.1rem,
      "1-3":1.3rem,
      "1-5": 1.5rem,
      "1-8": 1.809rem,
      "2": 2rem,
      "2-1": 2.1rem,
      "2-2": 2.2rem,
      "2-4": 2.418rem,
      "2-5": 2.5rem,
      "3": 2.9rem,
      "3-3": 3.3rem,
      "4": 3.4rem,
      "5": 4.8rem,
      "5-1": 5.1rem,
      "5-9": 5.9rem,
      "6-6": 6.6rem,
      "7-4": 7.4rem,
      "7-7": 7.7rem,
      "8": 8.1rem,
      "8-6": 8.6rem,
      "9": 9.482rem,
      "11": 11rem,
    ),
  ),
  "margin-right": (
    "prefix": "margin-r",
    "values": (
      "2vw":2vw,
      "0": 0rem,
      "0-3": 0.3rem,
      "0-6": 0.6rem,
      "1": 1rem,
      "1-2":1.2rem,
      "1-5":1.5rem,
      "1-6": 1.6rem,
      "2":2rem,
      "3": 3rem,
    ),
  ),
  "margin-bottom": (
    "prefix": "margin-b",
    "values": (
      "0": 0.5rem,
      "0-9":0.9rem,
      "1-1": 1.1rem,
      "1-2": 1.2rem,
      "1-4": 1.389rem,
      "1-5": 1.5rem,
      "1-6": 1.6rem,
      "1-8": 1.8rem,
      "1-9": 1.9rem,
      "2": 2rem,
      "2-1": 2.1rem,
      "2-2": 2.2rem,
      "2-5": 2.495rem,
      "3": 3.2rem,
      "3-8":3.8rem,
      "7-4":7.4rem,
      "8-5": 8.5rem,
      "10": 10rem,
    ),
  ),
  "margin-left": (
    "prefix": "margin-l",
    "values": (
      "2vw":2vw,
      "0": 0.551rem,
      "0-5": 0.5rem,
      "0-6": 0.6rem,
      "1": 1rem,
      "1-1": 0.6rem,
      "2-4": 2.4rem,
    ),
  ),
  "padding-top": (
    "prefix": "padding-t",
    "values": (
      "0-4":0.4rem,
      "0-6":0.6rem,
      "1": 1.08rem,
      "1-5": 1.5rem,
      "2": 2rem,
      "2-6": 2.6rem,
      "2-8": 2.8rem,
      "4-2": 4.2rem,
      "6": 5.911rem,
    ),
  ),
  "padding-right": (
    "prefix": "padding-r",
    "values": (
      "1": 1rem,
      "1-6":1.6rem,
      "2-2":2.2rem,
      "2-7":2.7rem,
      "3-2": 3.2rem,
      "5-2": 5.2rem,
    ),
  ),
  "padding-bottom": (
    "prefix": "padding-b",
    "values": (
      "0-4":0.4rem,
      "1": 0.8rem,
      "1-2":1.2rem,
      "1-6": 1.6rem,
      "2": 2rem,
      "2-1": 2.1rem,
      "2-3":2.3rem,
      "2-5": 2.5rem,
      "4-5" : 4.5rem
    ),
  ),
  "padding-left": (
    "prefix": "padding-l",
    "values": (
      "0": 0.3rem,
      "0-5": 0.5rem,
      "1": 0.9rem,
      "1-1": 1.1rem,
      "1-4": 1.4rem,
      "1-5": 1.47rem,
      "1-7":1.8rem,
      "1-9": 1.9rem,
      "3": 3rem,
      "3-8": 3.8rem,
      "3-2": 3.2rem,
      "4": 4rem,
      "5-2": 5.2rem,
    ),
  ),
  "font-weight": (
    "prefix": "weight",
    "values": (
      "rg": $font-weight-regular,
      "md": $font-weight-medium,
      "sb": $font-weight-semi-bold,
      "b": $font-weight-bold,
      "xb": $font-weight-x-bold,
    ),
  ),
  "font-size": (
    "prefix": "size",
    "values": (
      "xxxs": $font-xxxs,
      "xxs": $font-xxs,
      "xs": $font-xs,
      "sm": $font-sm,
      "md": $font-md,
      "xmd": $font-xmd,
      "xl": $font-xl,
      "xxl": $font-xxl,
      "xxxl": $font-xxxl,
      "xxxxl": $font-xxxxl,
      "xxxxxl": $font-xxxxxl,
    ),
  ),
  "font-family": (
    "prefix": "type",
    "values": (
      "1": $primary-font,
      "2": $secondary-font,
      "3": $tertiary-font,
    ),
  ),
  "line-height": (
    "prefix": "lh",
    "values": (
      "0-2": 0.2rem,
      "1-2": 1.2rem,
      "1-3": 1.3rem,
      "1-4": 1.4rem,
      "1-5": 1.5rem,
      "1-6": 1.6rem,
      "1-7": 1.7rem,
      "1-8": 1.8rem,
      "1-9": 1.9rem,
      "2": 2rem,
      "2-2": 2.2rem,
      "2-3": 2.3rem,
      "2-4": 2.4rem,
      "2-6": 2.6rem,
      "2-8": 2.8rem,
      "3": 3rem,
      "3-1": 3.1rem,
      "3-6": 3.6rem,
    ),
  ),
  "letter-spacing": (
    "prefix": "ls",
    values: (
      "0": 0.01rem,
      "1": 0.05rem,
      "2": -0.02rem,
      "3": 0.12rem,
      "4": -0.005rem,
    ),
  ),
);

/*generating utilities classes*/
@each $property, $map in $utilities {
  $prefix: map-get($map, "prefix");
  $values: map-get($map, "values");
  @each $k, $v in $values {
    .#{$prefix}-#{$k} {
      #{$property}: $v;
    }
  }
}

.text-center {
  text-align: center;
}
/*flex*/
.d-flex {
  display: flex;
}
.direction-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.p-absolute{
  position: absolute;
}
.p-relative{
  position: relative;
}
.p-fixed{
  position: fixed;
}
.w-100{
  width: 100%;
}
.h-100{
  height: 100%;
}
.w-7{
  width: 7rem;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.box-sizing-content {
  box-sizing: content-box;
}

.mr-bt-0{
  margin-bottom: 0;
}

.preloader{background-color:#f7f7f7;width:100%;height:100%;position:fixed;top:0;left:0;right:0;bottom:0;z-index:999999;-webkit-transition:.6s;-o-transition:.6s;transition:.6s;margin:0 auto}
.preloader .preloader-circle{width:100px;height:100px;position:relative;border-style:solid;border-width:3px;border-top-color:#FA6400;border-bottom-color:transparent;border-left-color:transparent;border-right-color:transparent;z-index:10;border-radius:50%;-webkit-box-shadow:0 1px 5px 0 rgba(35,181,185,0.15);box-shadow:0 1px 5px 0 rgba(35,181,185,0.15);background-color:#ffffff;-webkit-animation:zoom 2000ms infinite ease;animation:zoom 2000ms infinite ease;-webkit-transition:.6s;-o-transition:.6s;transition:.6s}.preloader .preloader-circle2{border-top-color:#0078ff}.preloader .preloader-img{position:absolute;top:50%;z-index:200;left:0;right:0;margin:0 auto;text-align:center;display:inline-block;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);padding-top:6px;-webkit-transition:.6s;-o-transition:.6s;transition:.6s}
.preloader .preloader-img img{max-width:55px}
.preloader .pere-text strong{font-weight:800;color:#dca73a;text-transform:uppercase}@-webkit-keyframes zoom{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition:.6s;-o-transition:.6s;transition:.6s}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);-webkit-transition:.6s;-o-transition:.6s;transition:.6s}}
@keyframes zoom{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg);-webkit-transition:.6s;-o-transition:.6s;transition:.6s}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg);-webkit-transition:.6s;-o-transition:.6s;transition:.6s}}
.d-flex{display:-ms-flexbox!important;display:flex!important}
.align-items-center{-ms-flex-align:center!important;align-items:center!important}
.justify-content-center{-ms-flex-pack:center!important;justify-content:center!important}

