/*profile*/
.accordian-box-container {
  width: 90vw;
  box-shadow: 0 0.2rem 0.4rem $black;
  border-radius: 1rem;
  background-color: $white;
}
.accordian-box {
  @include flexbox(normal, center);
  position: relative;
  min-height: 4.9rem;
  border-bottom: 0.1rem solid $snowGray;
  padding: 0.6rem 0.3rem 0.3rem 2.1rem;
}
.accordian-box[aria-expanded="true"] {
  border-bottom: none;
  // min-height: 3.9rem;
  // transition: 0.3s;
  .accordian-arrow {
    transform: rotate(90deg);
    transition: 0.3s;
    width: 54px;
  }
}
.accordian-arrow {
  position: absolute;
  right: 0;
}
.accordian-panel {
  border-bottom: 0.1rem solid $snowGray;
}
.accordian-panel-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  margin-bottom: 1rem;
  div {
    @include font($font-xs, $font-weight-medium, $secondary-font);
    min-width: 38vw;
    color: $color_1;
    margin-top: 0.8rem;
    padding: 1.6rem 0;
    text-align: center;
    line-height: 1.6rem;
    border: 0.1rem solid $snowGray;
    border-radius: 0.4rem;
  }
}

/*faq*/
.accordian-box-2 {
  position: relative;
  border-bottom: 0.1rem solid $snowGray;
  padding: 2rem 0 2.1rem 1.6rem;
  .accordian-arrow {
    right: 2rem;
    top: 2.7rem;
  }
}
.accordian-box-2[aria-expanded="true"] {
  border-bottom: none;
  p {
    color: $productColor2;
    padding-left: 1.1rem;
    transition: 1s;
  }
  .accordian-arrow {
    transform: rotate(-180deg);
    transition: 0.3s;
  }
}
.accordian-panel-2{
  position: relative;
  padding-left: 1.6rem;
  p{
    padding-left: 1.4rem;
  }
}
.accordian-panel-2::before{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: .5rem;
  width: .5rem;
  height: 100%;
  background-color: $productColor2;
  margin-left: 0.9rem;
}

.margin-15 {
  margin-top: -15px;
}
.left-5-8 {
  left : 5.8rem;
}