/*expired */
#expired {
  @include font($font-xs, $font-weight-medium, $secondary-font);
  @include alignment(1.8rem, 0.05rem);
  color: $red;
}

/*know more page*/
.wrapper-type-1-text {
  font-weight: $font-weight-regular;
  padding: 0 0 2.1rem 0;
  margin: 1rem 0 0 3.71rem;
  color: $color_1;
}

.product-name-review-paragraph {
  font-size: $font-xl;
  font-weight: $font-weight-semi-bold;
}

.review-attachment-heading {
  font-weight: $font-weight-medium;
  font-size: $font-xl;
}
.pre-line {
  white-space: pre-line;
}

.otp-text
{
  font-size: $font-xs;
  font-weight: $font-weight-semi-bold;
  letter-spacing: 0;
}
.resend-otp-text
{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.0428571px;
  color: #AFAFAF;
  margin-top: 8px;
}
.resend-otp-text-again
{
  @extend .resend-otp-text;
  color: #E8630A;
  font-weight: 600;
}
.resend-otp-timer
{
  @extend .resend-otp-text;
  color: #E8630A;
  margin-left: 5px;
}
