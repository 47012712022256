// 1rem=10px
* {
  padding: 0;
  margin: 0;
}
body {
  font-family: $primary-font;
  font-weight: $font-weight-regular;
}
a {
  text-decoration: none;
}

/*payment status css START*/ 
.payment-integration{
  width: 130px !important;
  height: 130px !important;
  margin-top: 100px !important;
}
.payment-integration-popup{
  width: 80px !important;
  height: 80px !important;
}
.payment-integration-popup-close{
  margin-left: auto;
}
/*payment status css END*/ 
/*Payment Loader Css Start*/ 
.payment-loader-text{
position: absolute;
left: 6.4%;
right: 5.87%;
top: 52.22%;
bottom: 45.44%;

font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
/* identical to box height */

text-align: center;
}
.payment-loader-img{
  width: 100px !important;
  padding-bottom: 12rem
}
.payment-loader-timer-text{
position: absolute;
left: 34.13%;
right: 33.6%;
top: 92.24%;
bottom: 5.79%;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
text-align: center;
}
.payment-loader-timer{
  color: $productColor;
}
.payment-explore-more-button{
  background-color: white;
  color: #035397;
  margin-top: 15px;
  font-weight: bold;
  border-radius: 0.4rem;
  border: 1px solid;
  font-size: 1.6rem;
}
/*Payment Loader Css END*/ 