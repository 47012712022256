/*1rem=10px*/

// notification screen
.wrapper-type-2 {
  @include flexbox(normal, center);
  padding: 1.8rem 1.1rem 2rem 1.6rem;
  border-bottom: 1px solid $cloudGray;
  border-top: 1px solid $cloudGray;
}

/*know more page-wrapper for button*/
.wrapper-button {
  @include flexbox(center, center);
  width: 100vw;
  padding: 2.85rem 0;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top: 1px solid $snowGray;
  background-color: $white;
}

/*location-page*/
.wrapper-button-2 {
  @extend .wrapper-button;
  align-items: unset;
  border-top: none;
  padding: 1vh 0 4vh 0;
}

/*survey-page*/
.wrapper-button-3 {
  @extend .wrapper-button-2;
  padding-bottom: 2.1rem;
  padding-top: 0;
  background-color: transparent;
}

/*change profile*/
.wrapper-button-4 {
  @extend .wrapper-button-2;
  padding-bottom: 8.5vh;
  padding-top: 0;
}

/*Confirm location & Select Location*/
.wrapper-button-5 {
  @extend .wrapper-button-4;
  justify-content: space-between;
  width: 90vw;
}

/*review survey*/
.wrapper-button-6 {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  background-color: $paleWhite;
  padding: 3.5vh 0;
  width: 90vw;
  z-index: 1;
}

/*CreateProfile*/
.wrapper-button-7 {
  @extend .wrapper-button-5;
}

/*NoNetworkScreen*/
.nonetwork {
  @include flexbox(center, center, column);
  position: fixed;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.capital {
  text-transform: capitalize;
}

/*Application status Page */
.wrapper-button-status
{
  @extend .wrapper-button;
  flex-direction: column;
  gap:2vh;
}
.invalid-otp-error-container
{
  width: 100%;
  float: left;
  position: relative;
  bottom: 5px;
  left: 6px;
}
.otp-error-container
{
  position: relative;
  top: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 10px;
  // gap: 10px;
  width: 247px;
  background: rgba(244, 208, 208, 0.75);
  border-radius: 5px;
}
.resend-otp-timer-container
{
  margin-top: 8px;
}
/* end */
