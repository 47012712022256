/*1rem=10px*/
/*copy-button & download button*/
.bttn-light {
  @include flexbox(center, center);
  background: $white;
  border: 0.02rem solid $lightWhite;
  box-shadow: 0 0.2 0.4rem $black;
  border-radius: 0.6rem;
  white-space: nowrap;
  span {
    @include font($font-xxxs, $font-weight-medium, $secondary-font);
    margin-left: 0.5rem;
    line-height: 2rem;
    color: $productColor2;
  }
}
/*copied button*/
.bttn-info {
  @extend .bttn-light;
  background: $turquoiseBlue;
  white-space: nowrap;
  span {
    color: $white;
  }
}
/*share button*/
.bttn-success {
  @extend .bttn-light;
  background-color: $parrotGreen;
  border: none;
  span {
    color: $white;
  }
}
/*primary button*/
.bttn-primary {
  background-color: $productColor;
  border-radius: 0.4rem;
  border: none;
  font-size: $font-xl;
  color: $white;
}

// disabled button
.disable {
  background: $lavaGray;
}
/*when button is disable*/
.disable-2{
  background-color: $lavaGray!important;
  color: $white;
}

// delete & save button-popup
.bttn-secondary-dark {
  border-radius: 0.4rem;
  background: $productColor;
  border: none;
  span {
    @include font($font-xl, $font-weight-medium, $secondary-font);
    line-height: 2.4rem;
    color: $white;
  }
}
// cancel button-popup
.bttn-secondary-light {
  @extend .bttn-secondary-dark;
  border: 0.1rem solid $productColor;
  background-color: $white;
  span {
    color: $productColor;
  }
}

//home page-animated button
.bttn-animated {
  background-image: url("../scssImgs/animation.gif");
  background-position: center;
  width: 16.5rem;
  height: 3.9rem;
  border: none;
  border-radius: 0.6rem;
  span {
    @include font($font-sm, $font-weight-medium, $secondary-font);
    color: $white;
  }
}
/*buttons based on size*/
.bttn-xs {
  padding: 0.4rem 1rem;
}
.bttn-xxs {
  padding: 0.4rem 0.8rem;
}
.bttn-md {
  @include btn(50%, 4.3rem);
}
.bttn-md-1 {
  min-height: 4.3rem;
  min-width: 43vw;
}
.bttn-md-2 {
  min-height: 4.5rem;
  min-width: 42vw;
}
.bttn-lg { 
  @include btn(50%, 4.6rem);
}
.bttn-xl {
  @include btn(100%, 4.6rem);
}
.bttn-l{
  width: 54vw;
  padding: 1.35rem;
}
.bttn-xxl {
  padding: 1.35rem;
  width: 90vw;
  z-index: 9999;
}
.bttn-max{
  width: 100%;
}

/*Lets get started button*/
.btn-getstarted {
  @include font($font-xl, $font-weight-medium);
  @include alignment(1.9rem, 0.05rem);
  width: 24.5rem;
  height: 5.4rem;
  color: $white;
  border: none;
  background: $productColor;
  border-radius: 0.6rem;
  position: relative;
  span {
    z-index: 1;
    position: relative;
  }
}
.btn-getstarted-img-1 {
  width: 100%;
  top: 0;
  right: 0;
}
.btn-getstarted-img-2 {
  top: 2rem;
  right: 1.5rem;
}

/*my scheme on home page proceed button*/
.proceed-arrow {
  position: absolute;
  right: 3.178rem;
}

/*profile page-add profile button*/
.add-profile {
  @include flexbox(center, center);
  @include font($font-xs, $font-weight-medium);
  width: 26.5rem; /*70.6vw*/
  color: $productColor2;
  padding: 0.45rem 0;
  background-color: $white;
  line-height: 1.4rem;
  border: 0.1rem solid $soot;
  filter: drop-shadow(0 .1rem .2rem $black);
  border-radius: 0.5rem;
}

.bttn-primary-thankyou {
  background-color: $productColor;
  border-radius: 0.4rem;
  border: none;
  // font-size: $font-xl;
  color: $white;
  margin-bottom: 5px;
}
.bttn-primary-explore-more {
  // background-color: $productColor;
  border-radius: 0.4rem;
  border: 1px solid black;
  // font-size: $font-xl;
  // color: $white;
  margin-bottom: 5px;
}

.bttn-xxl-benefit {
  background-color: $white;
  font-weight: bold;
  border-radius: 0.4rem;
  font-size: $font-xl;
  color: $productColor;
  padding: 1.35rem;
  width: 90vw;
  z-index: 9999;
  border: 1px solid #035397;
}

.scheme_support{
  margin-top: 6px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: #E8630A;
}

/*primary button-get-scheme*/
.bttn-primary-get-scheme {
  background-color: $productColor;
  border-radius: 0.4rem;
  font-weight: bold;
  border: none;
  font-size: $font-xl;
  color: $white;
}
.bttn-resend-otp
{
  background-color: #035397;
  border-radius: 0.4rem;
  border: none;
  font-size: 1.2rem;
  color: #ffffff;
  padding: 7px;
}
