// 1rem =10px
@import "color";
@import "font";

//search bar in location page
.search-1 {
  width: 100%;
  padding: 0.98335rem 0 0.98335rem 0.9rem;
  box-sizing: border-box;
  border: 0.1rem solid $darkGray;
  &::placeholder {
    @include alignment(1.6rem, 0.05rem);
    @include font($font-md, $font-weight-medium, $secondary-font);
    color: $darkGray;
  }
  &:focus {
    border: 0.1rem solid $productColor2;
    outline: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
.search-1-values {
  display: flex;
  flex-direction: column;
  border: 0.1rem solid $grandmaGray;
  border-radius: 0 0 0.4rem 0.4rem;
  padding: 1.1rem 0.9rem 1.2rem 0.9rem;
  ol li {
    font-size: $font-md;
    color: $color_1;
    list-style: none;
    &:not(:last-child) {
      padding-bottom: 2.4rem;
    }
  }
}

/*search in search screen*/
.search-main-container-2 {
  background-color: $productColor;
  padding: 1.8rem 0;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 2;
}
.search-2 {
  border: none;
  outline: none;
  position: relative;
  border-radius: 0.5rem;
  background: url("../scssImgs/search.svg") no-repeat $white 9px center;

  input {
    @extend .search-2;
    padding: 1.55rem 3.8rem 1.55rem 4.5rem;
    width: 64vw;
    &:focus + label {
      display: none;
    }
    &:focus ~ marquee {
      display: none;
    }
    &:focus {
      outline: none;
      border: none;
    }
  }
  label,
  marquee {
    position: absolute;
    pointer-events: none;
  }
  label {
    left: 4.5rem;
    top: 1.4rem;
  }
  marquee {
    top: 1.4rem;
    left: 10.8rem;
  }
}
.ml .search-2{
  marquee{
    left:11.4rem;
  }
}

//search bar in tooltip
.search-3 {
  width: 100%;
  background: #f2f2f2;
  border-radius: 4px;
  border: none;
  padding: 5px;
  &::placeholder {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.0469134px;
    color: #b4b4b4;
    font-style: italic;
  }
  &:focus {
    border: none;
    outline: none;
  }
}
//search in confirm location page
.search-4-container {
  padding: 1.7rem 4rem 1.7rem 1.7rem;
  border-top: 0.1rem solid $grandmaGray;
  border-left: 0.1rem solid $grandmaGray;
  border-right: 0.1rem solid $grandmaGray;
  border-radius: .5rem .5rem 0 0;
}
.search-4 {
  padding: 1.2rem 0.9rem 1.1rem 0.9rem;
  width: 100%;
  border: 0.08rem solid $productColor !important;
  box-shadow: 0.1rem 0.1rem 0.4rem rgb(3 83 151 / 15%);
  border-radius: 0.8rem;
  &::placeholder {
    font-style: italic;
  }
  &:focus {
    outline: none !important;
    border: 0.08rem solid $productColor !important;
  }
}
//search bar in location page with dropdown
.react-datalist-input__container>* {
  box-sizing: border-box;
}
.react-datalist-input__textbox {
  padding: var(--padding);
  border: var(--border);
  border-radius: var(--border-radius);
  display: inline-block;
  border-radius: 0 !important;
  width: 100%;
  font-size: $font-sm !important;
  line-height: 2rem;
  margin: 0;
  padding: 0.9rem !important;
  &:focus {
    border: 0.1rem solid $productColor2;
    outline: none;
  }
  text-transform: capitalize;
  &::placeholder {
    @include alignment(1.6rem, 0.05rem);
    @include font($font-md, $font-weight-medium, $secondary-font);
    color: $darkGray;
    text-transform: capitalize;
  }
}
.react-datalist-input__container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border: 1px solid $grandmaGray ;
  z-index: 99999 !important;
  border-radius: 0.2rem;
  text-transform: capitalize;
}
.react-datalist-input__listbox {
  position: absolute;
  margin: 2px 0 0 0;
  border-radius: 0 0 0.4rem 0.4rem;
  background-color: $white;
  margin-top: 8px !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  max-height: 28vh;
  z-index: 99999 !important;
  flex-direction: column;
  list-style-type: none;
  border: 1px solid $grandmaGray ;
  width: 89vw;

}
.react-datalist-input__listbox-option {
  margin-top: 4px;
  width: 100%;
  cursor: pointer;
  padding: 0.25rem;
  font-size: $font-md;
  list-style-type: none;
 text-transform: capitalize;;
  display: inline-block;
  mark {
    background-color: $white;
    color:$lavaGray;
  }
}

