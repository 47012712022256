.profile-bar {
  @include flexbox(space-between, center);
  position: fixed;
  top: 0;
  z-index: 999;
  height: 4rem;
  width: 100vw;
  box-shadow: 0 0.2rem 0.5rem $black;
}
.profile-bar-left {
  @include flexbox(normal, center);
  padding-left: 1.3rem;
  h2 {
    @include alignment(1.4rem, 0.05rem);
    padding-left: 1.1rem;
  }
}
.profile-bar-right {
  margin-right: 1.6rem;
}

/*survey bar*/
.bar-type-2 {
  max-width: 100vw;
  height: 4rem;
  filter: drop-shadow(0 .4rem .8rem $silverGray);
  display: flex;
  align-items: center;
  padding: 0 1.1rem;
  background-color: $dewWhite;
  overflow: hidden;
  position: relative;
  p {
    font-size: $font-xs;
    color: $peaGreen;
  }
}

/*notification bar*/
.bar-type-3 > div {
  display: flex;
  justify-content: space-between;
  padding: 1.4rem 1.4rem 1.5rem 1.7rem;
  border-bottom: 1px solid $cloudGray;
}
.bar-type-3 > div > div {
  display: flex;
  align-items: center;
}

/*review survey page*/
.bar-type-4 {
  @extend .bar-type-2;
  background-color: $almond;
  p {
    color: $productColor;
  }
}

/* review application details*/
.bar-type-4-fixed {
  @extend .bar-type-4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
