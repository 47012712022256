/*1rem=10px*/
// english
@font-face {
  font-family: "Roboto-Regular";
  src: url(../fonts/Roboto/Roboto-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url(../fonts/Roboto/Roboto-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url(../fonts/Roboto/Roboto-Bold.ttf);
  font-display: swap;
}
//hindi & marathi
@font-face {
  font-family: "Mukta-Regular";
  src: url(../fonts/Mukta/Mukta-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Mukta-Medium";
  src: url(../fonts/Mukta/Mukta-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Mukta-Bold";
  src: url(../fonts/Mukta/Mukta-Bold.ttf);
  font-display: swap;
}
//kannada
@font-face {
  font-family: "Noto-Sans-Kannada-Regular";
  src: url(../fonts/NotoSansKannada/NotoSansKannada-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Kannada-Medium";
  src: url(../fonts/NotoSansKannada/NotoSansKannada-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Kannada-Bold";
  src: url(../fonts/NotoSansKannada/NotoSansKannada-Bold.ttf);
  font-display: swap;
}

//tamil
@font-face {
  font-family: "Noto-Sans-Tamil-Regular";
  src: url(../fonts/NotoSansTamil/NotoSansTamil-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Tamil-Medium";
  src: url(../fonts/NotoSansTamil/NotoSansTamil-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Tamil-Bold";
  src: url(../fonts/NotoSansTamil/NotoSansTamil-Bold.ttf);
  font-display: swap;
}
//Punjabi
@font-face {
  font-family: "Noto-Sans-Gurmukhi-Regular";
  src: url(../fonts/NotoSansGurmukhi/NotoSansGurmukhi-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Gurmukhi-Medium";
  src: url(../fonts/NotoSansGurmukhi/NotoSansGurmukhi-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Gurmukhi-Bold";
  src: url(../fonts/NotoSansGurmukhi/NotoSansGurmukhi-Bold.ttf);
  font-display: swap;
}
//Malayalam
@font-face {
  font-family: "Noto-Sans-Malayalam-Regular";
  src: url(../fonts/NotoSansMalayalam/NotoSansMalayalam-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Malayalam-Medium";
  src: url(../fonts/NotoSansMalayalam/NotoSansMalayalam-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Malayalam-Bold";
  src: url(../fonts/NotoSansMalayalam/NotoSansMalayalam-Bold.ttf);
  font-display: swap;
}
//Bengali
@font-face {
  font-family: "Noto-Sans-Bengali-Regular";
  src: url(../fonts/NotoSansBengali/NotoSansBengali-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Bengali-Medium";
  src: url(../fonts/NotoSansBengali/NotoSansBengali-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Bengali-Bold";
  src: url(../fonts/NotoSansBengali/NotoSansBengali-Bold.ttf);
  font-display: swap;
}
//telegu
@font-face {
  font-family: "Noto-Sans-Telegu-Regular";
  src: url(../fonts/NotoSansTelugu/NotoSansTelugu-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Telegu-Medium";
  src: url(../fonts/NotoSansTelugu/NotoSansTelugu-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Telegu-Bold";
  src: url(../fonts/NotoSansTelugu/NotoSansTelugu-Bold.ttf);
  font-display: swap;
}
//gujrati
@font-face {
  font-family: "Noto-Sans-Gujrati-Regular";
  src: url(../fonts/Noto_Sans_Gujarati/NotoSansGujarati-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Gujrati-Medium";
  src: url(../fonts/Noto_Sans_Gujarati/NotoSansGujarati-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: "Noto-Sans-Gujrati-Bold";
  src: url(../fonts/Noto_Sans_Gujarati/NotoSansGujarati-Bold.ttf);
  font-display: swap;
}

//font weight variables
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-x-bold: 800;

//font family variables
$font-type-1-1: "Roboto-Regular";
$font-type-1-2: "Roboto-Medium";
$font-type-1-3: "Roboto-Bold";
$font-type-2-1: "Mukta-Regular";
$font-type-2-2: "Mukta-Medium";
$font-type-2-3: "Mukta-Bold";
$font-type-3-1: "Noto-Sans-Tamil-Regular";
$font-type-3-2: "Noto-Sans-Tamil-Medium";
$font-type-3-3: "Noto-Sans-Tamil-Bold";
$font-type-4-1:"Noto-Sans-Bengali-Regular";
$font-type-4-2:"Noto-Sans-Bengali-Medium";
$font-type-4-3:"Noto-Sans-Bengali-Bold";
$font-type-5-1: "Noto-Sans-Kannada-Regular";
$font-type-5-2: "Noto-Sans-Kannada-Medium";
$font-type-5-3: "Noto-Sans-Kannada-Bold";
$font-type-6-1: "Noto-Sans-Malayalam-Regular";
$font-type-6-2: "Noto-Sans-Malayalam-Medium";
$font-type-6-3: "Noto-Sans-Malayalam-Bold";
$font-type-7-1: "Noto-Sans-Gurmukhi-Regular";
$font-type-7-2: "Noto-Sans-Gurmukhi-Medium";
$font-type-7-3: "Noto-Sans-Gurmukhi-Bold";
$font-type-8-1: "Noto-Sans-Telegu-Regular";
$font-type-8-2: "Noto-Sans-Telegu-Medium";
$font-type-8-3: "Noto-Sans-Telegu-Bold";
$font-type-9-1: "Noto-Sans-Gujrati-Regular";
$font-type-9-2: "Noto-Sans-Gujrati-Medium";
$font-type-9-3: "Noto-Sans-Gujrati-Bold";

$primary-font: var(--primary-font);
$secondary-font: var(--secondary-font);
$tertiary-font: var(--tertiary-font);

//applying fonts according to the language selected
@mixin font-family($fontFamily, $type) {
  @if $type == en {
    font-family: $fontFamily;
  } @else if $type == hi {
    font-family: $fontFamily;
  } @else if $type == ta {
    font-family: $fontFamily;
  } @else if $type == bn {
    font-family: $fontFamily;
  } @else if $type == kn {
    font-family: $fontFamily;
  } @else if $type == ml {
    font-family: $fontFamily;
  } @else if $type == pa {
    font-family: $fontFamily;
  } @else if $type == te {
    font-family: $fontFamily;
  } @else if $type == gu {
    font-family: $fontFamily;
  }
}

body,
body.en {
  //applying font to the body
  @include font-family($font-type-1-1, en);
  //these variables are being used in the mixims and utility classes
  --primary-font: #{$font-type-1-1};
  --secondary-font: #{$font-type-1-2};
  --tertiary-font: #{$font-type-1-3};
}
body.hi,body.mr,
#mr span:nth-of-type(1) {
  @include font-family($font-type-2-1, hi);
  --primary-font: #{$font-type-2-1};
  --secondary-font: #{$font-type-2-2};
  --tertiary-font: #{$font-type-2-3};
}
body.ta {
  @include font-family($font-type-3-1, ta);
  --primary-font: #{$font-type-3-1};
  --secondary-font: #{$font-type-3-2};
  --tertiary-font: #{$font-type-3-3};
}
body.bn {
  @include font-family($font-type-4-1, bn);
  --primary-font: #{$font-type-4-1};
  --secondary-font: #{$font-type-4-2};
  --tertiary-font: #{$font-type-4-3};
}
body.kn {
  @include font-family($font-type-5-1, kn);
  --primary-font: #{$font-type-5-1};
  --secondary-font: #{$font-type-5-2};
  --tertiary-font: #{$font-type-5-3};
}
body.ml {
  @include font-family($font-type-6-1, ml);
  --primary-font: #{$font-type-6-1};
  --secondary-font: #{$font-type-6-2};
  --tertiary-font: #{$font-type-6-3};
}

body.pa {
  @include font-family($font-type-7-1, pa);
  --primary-font: #{$font-type-7-1};
  --secondary-font: #{$font-type-7-2};
  --tertiary-font: #{$font-type-7-3};
}
body.te {
  @include font-family($font-type-8-1, te);
  --primary-font: #{$font-type-8-1};
  --secondary-font: #{$font-type-8-2};
  --tertiary-font: #{$font-type-8-3};
}
body.gu {
  @include font-family($font-type-9-1, ge);
  --primary-font: #{$font-type-9-1};
  --secondary-font: #{$font-type-9-2};
  --tertiary-font: #{$font-type-9-3};
}


#en,#hi,#ta,#bn,#kn,#ml,#pa,#te,#gu,#mr span:nth-of-type(2){
  --primary-font: #{$font-type-1-1};
}
#eng{
  font-family: "Roboto-Regular";
  --primary-font: #{$font-type-1-1};
  --secondary-font: #{$font-type-1-2};
  --tertiary-font: #{$font-type-1-3};
}
// font sizes
$font-xxxs: 1rem;
$font-xxs: 1.1rem;
$font-xs: 1.2rem;
$font-sm: 1.3rem;
$font-md: 1.4rem;
$font-xmd: 1.5rem;
$font-xl: 1.6rem;
$font-xxl: 1.8rem;
$font-xxxl: 1.9rem;
$font-xxxxl: 2.4rem;
$font-xxxxxl: 4rem;


