/*slides anim when tutorial screen is visible*/
/*add class anim-4 to survey-main-box when the tutorial screen is visible*/
.anim-4 {
  animation: example 2s infinite;
}
@keyframes example {
  from {
    left: 0;
  }
  to {
    left: 3.5rem;
  }
}
.anim-5 {
  animation: gelatin 1s ease-in infinite;
}
@keyframes gelatin {
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}