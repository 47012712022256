/*create-profile*/
.input-wrapper-type-1 {
  position: relative;
}
.input-type-1 {
  @include font(1.6rem, $font-weight-semi-bold);
  border: 0.1rem solid $grandmaGray;
  border-radius: 0.4rem;
  padding: 1.5rem 0 1.4rem 1.683rem;
  width: 87vw;
  line-height: 1.9rem;
  color: $color_1;
}
.input-type-2 {
  @include font(1.6rem, $font-weight-semi-bold);
  border: 0.1rem solid #c5c5c5;
  border-radius: 0.4rem;
  padding: 1.5rem 0 1.4rem 1.683rem;
  width: 64vw;
  line-height: 1.9rem;
  height: 1rem;
  bottom: 1rem;
  color: #383838;
}

// add name popup & create-profile
.input-anim-1 {
  outline: none;
  position: relative;
  &:focus + label,
  &:not(:invalid) + label {
    transform: translateY(-23px);
    font-size: $font-xs;
  }
}
.label-anim-1 {
  position: absolute;
  z-index: 2;
  left: 2rem;
  top: 1.4rem;
  pointer-events: none;
  transition: transform 100ms ease;
  transform: translateY(0);
  font-size: $font-xl;
  background-color: $white;
}
.label-anim-2 {
  @extend .label-anim-1;
  transform: translateY(-23px);
  font-size: $font-xs;
}

//select menu in create profile page & review survey & manage profile -multiselect & confirm location
.select-1__indicator-separator,
.select-2__indicator-separator,
.select-3__indicator-separator,
.multi-select__indicator-separator {
  display: none;
}

.select-1__control--menu-is-open,
.select-2__control--menu-is-open,
.select-3__control--menu-is-open,
.multi-select__control--menu-is-open {
  .select-1__indicator,
  .select-2__indicator,
  .select-3__indicator,
  .multi-select__indicator svg {
    transform: rotate(180deg);
    transition: transform 100ms ease;
  }
}
.select-1__indicator,
.select-2__indicator,
.select-3__indicator,
.multi-select__indicator {
  svg {
    fill: $productColor;
  }
}
.select-1__input,
// .select-2__input,
.select-3__input,
.multi-select__input {
  display: none;
}
.select-1__option,
.select-2__option,
.select-3__option,
.multi-select__option {
  background-color: transparent !important;
}
//select menu in create profile page & review survey & confirm location
.select-1__menu-list,
.select-2__menu-list,
.select-3__menu-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  &:focus-visible {
    outline: none !important;
  }
}
.select-1__option--is-focused,
.select-2__option--is-focused,
.select-3__option--is-focused {
  // background-color: $skyBlue !important;
}

//select menu in review survey & multiselect & confirm location
.select-1_control--is-focused,
.select-2__control--is-focused,
.select-3__control--is-focused .multi-select__control--is-focused {
  box-shadow: none !important;
  border: none !important;
}

//select menu in create profile page
.select-1__value-container {
  position: relative;
  padding: 1.5rem 0 1.4rem 1.683rem !important;
}
.select-1__single-value {
  @include font($font-xl, $font-weight-semi-bold);
  line-height: 1.9rem;
  color: $color_1;
}
// .select-1__menu-list{
//   height: 30vh;
// }
.select-1__placeholder {
  font-size: $font-xl !important;
  background-color: $white;
  color: $lavaGray !important;
  pointer-events: none;
  transition: transform 100ms ease;
}

.select-1__menu {
  margin-top: 0 !important;
  box-shadow: none !important;
  border: 0.1rem solid $grandmaGray;
  border-radius: 0.4rem !important;
}

.select-1__option {
  padding: 1.3rem 1.2rem !important;
  font-weight: $font-weight-regular;
  font-size: 1.6rem !important;
  line-height: 1.9rem;
  color: $color_1 !important;
  &:focus-visible {
    outline: none !important;
  }
}
.select-1__control--is-focused {
  border: 0.1rem solid $grandmaGray !important;
  box-shadow: none !important;
}
//select menu in review survey page
.select-2__value-container {
  padding: 1.8rem 1.5rem;
}
.select-2__option {
  font-weight: $font-weight-medium;
  font-size: $font-md !important;
  line-height: 150%;
  color: $darkGrayishCyan !important;
  font-family: $secondary-font;
  &:focus-visible {
    outline: none !important;
  }
}
.select-2__menu {
  border-radius: 0 !important;
  margin-top: 0.1rem !important;
  box-shadow: 0px 10px 15px -2px grey !important;
}
.select-2__option {
  padding: 1.2rem 1.5rem !important;
}
.select-2__control {
  border: none !important;
  border-radius: 0.2rem !important;
  padding: 0.6rem 0.4rem;
}
.select-2__single-value {
  font-size: 1.6rem;
  line-height: 1.2rem;
  letter-spacing: 0.04375rem;
  color: $color_1;
  padding: 0.5rem 0;
}

.select-2__control--menu-is-open {
  border: 0.06rem solid $productColor !important;
}

//when select menu in review page is disabled
.select-2__control--is-disabled {
  background-color: $snowGray !important;
  .select-2__indicator svg {
    visibility: hidden;
  }
}
.multi-select__control--is-disabled {
  background-color: #e6e6e6 !important;
  .multi-select__multi-value {
    background: $fogGray !important;
    border: 0.05rem solid $spanishGray;
  }
  .multi-select__multi-value__label {
    color: $spanishGray !important;
  }
  .multi-select__indicator svg,
  .multi-select__multi-value__remove {
    visibility: hidden;
  }
  .multi-select__value-container {
    padding: 0 !important;
  }
  .multi-select__control {
    min-height: auto !important;
  }
  .multi-select__multi-value {
    margin-bottom: 0 !important;
  }
}

//manage profile -multiselect
.multi-select__menu {
  margin-top: 0 !important;
  box-shadow: none !important;
  border-top: 0.1rem solid $grandmaGray;
  border-radius: 0 0 0.4rem 0.4rem !important;
  box-shadow: 0px 10px 15px grey !important;
}
.multi-select__menu-list {
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem 0.5rem 0.5rem 0.5rem !important;
}
.multi-select__value-container {
  padding: 1.5rem 0.5rem 0.5rem 0.5rem !important;
}
.multi-select__control {
  border: none !important;
}
.multi-select__multi-value {
  padding: 1.2rem 1rem !important;
  background: $seaShell !important;
  border: 0.1rem solid $productColor2;
  border-radius: 0.6rem !important;
  line-height: 1.2rem;
  margin-bottom: 1rem !important;
}
.multi-select__multi-value__remove::after {
  content: url("../scssImgs/manageProfile/cross.svg");
}
.multi-select__multi-value__remove svg {
  display: none;
}
.multi-select__multi-value__label {
  color: $productColor2 !important;
  font-size: $font-md !important;
  line-height: 0.6rem;
}
.multi-select__option {
  @include alignment(1.4rem, 0.437.5rem);
  width: auto !important;
  padding: 1rem 1rem 1rem 0.8rem !important;
  background: $skyBlue !important;
  border: 0.05rem solid $productColor;
  border-radius: 0.6rem;
  color: $color_1 !important;
  display: flex !important;
  align-items: center;
  font-size: $font-md !important;
  color: $color_1 !important;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.field-text-ans {
  color: $approxGray;
  font-size: 1.6rem;
  padding-top: 0.4rem;
}

.multi-select__option::after {
  padding-left: 1.2rem;
  content: url(../scssImgs/manageProfile/add.svg);
}

.multi-select__menu-notice--no-options {
  font-size: 1.2rem;
  color: $porpoiseGray !important;
}

.select-2__option--is-selected{
  background-color:rgba(3, 83, 151, 0.15)!important;
}

//select menu in confirm location
.select-3__menu-list {
  border-left: 0.1rem solid $grandmaGray;
  border-right: 0.1rem solid $grandmaGray;
  border-bottom: 0.1rem solid $grandmaGray;
  border-radius: 0 0 0.4rem 0.4rem;
}
.select-3__value-container {
  padding: 1.8rem 1.5rem;
}
.select-3__option {
  font-size: $font-xl !important;
  line-height: 1.9rem;
  color: $color_1 !important;
  &:focus-visible {
    outline: none !important;
  }
}
.select-3__menu {
  border-radius: 0 !important;
  margin-top: 0.1rem !important;
  box-shadow: none !important;
}
.select-3__option {
  padding: 1.2rem 1.5rem !important;
}
.select-3__control {
  border: 0.1rem solid $grandmaGray !important;
  border-radius: 0.5rem !important;
  padding: 0.6rem 0.4rem;
}
.select-3__single-value {
  font-size: $font-xl;
  line-height: 1.9rem;
  color: $gordansGreen !important;
  padding: 0.5rem 0;
}
.select-3__control--menu-is-open {
  border: 0.1rem solid $grandmaGray !important;
}
//when no result
.select-3__menu-notice--no-options {
  padding: 1.1rem 2rem !important;
  text-align: left !important;
  font-size: $font-md;
  line-height: 1.6rem;
}
.select-3__menu-notice {
  border-top: 0.1rem solid $grandmaGray;
}
//select in confirm location page
.select-3__menu-list {
  max-height: 26.5rem !important;
}

.input-type-100{
  border: 0.1rem solid $grandmaGray;
  border-radius: 0.4rem;
  padding: 1.8rem;
  position: relative;
}

.input-type-100 label{
  position: absolute;
  top: -0.5rem;
  background-color: $white;
  color : $porpoiseGray
}

