/*1rem=10px*/
@mixin flexbox($jc, $ai, $fd: row) {
  display: flex;
  flex-direction: $fd;
  justify-content: $jc;
  align-items: $ai;
}
@mixin font($size, $weight, $family: $primary-font) {
  font-size: $size;
  font-weight: $weight;
  font-family: $family;
}
@mixin alignment($lineheight, $letterspacing) {
  letter-spacing: $letterspacing;
  line-height: $lineheight;
}
@mixin btn($width, $height) {
  width: $width;
  height: $height;
}

//triple dot functionality-after 1 line
@mixin tripledotone {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
//triple dot functionality-after 2 line
@mixin tripledottwo {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@mixin tripledotthree {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 32px;
}