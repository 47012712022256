/*1rem=10px*/
//introduction page slider
.slider-container {
  overflow: hidden;
}
.slide-img-container {
  height: 60vh;
  width: 100vw;
  text-align: center;
  display: flex !important;
  justify-content: center;
  outline: none;
}
.slide-img-1 {
  width: 100vw;
  height: 55vh;
  object-fit: cover;
  object-position: bottom;
  background:$litesky;
  // overflow-x: hidden;
}
.slide-img-2 {
  z-index: 999;
  top: 4.4rem;
}
.slide-img-container p {
  @include font($font-xxl, $font-weight-bold);
  font-weight: $font-weight-bold;
  width: 21.9rem;
  top: 30rem;
  line-height: 2.8rem;
  z-index: 999;
  color: $leatherBlack;
  font-family: $tertiary-font;
  font-style: normal;
}
//advertisment slider
@media (min-width: 320px) {
  .adv-slider-img {
    width: 100%;
    height: 12.7rem;
    border-radius: 6px;
  }
  .center {
    .slick-slide > div {
      padding: 0 1.4rem 0 0;
    }
    .slick-list .slick-track {
      margin-left: 2.1rem;
    }
  }
}
@media (min-width: 375px) {
  .adv-slider-img {
    height: 13.7rem;
  }
}
@media (min-width: 470px) {
  .adv-slider-img {
    height: 15.7rem;
  }
}
@media (min-width: 540px) {
  .adv-slider-img {
    height: 17.7rem;
  }
}
// categories slider
.three-half {
  .slick-list .slick-track {
    left: 5vw;
    margin-left: 0px;
  }
  .slick-slide > div {
    padding: 0 2rem 0 0;
  }
}
// scheme & job slider
.one-half {
  .slick-list .slick-track {
    left: 5vw;
  }
  .slick-slide > div {
    padding: 0 2rem 0 0;
  }
}
//service 
.slick-list{
  overflow: inherit;
}
.two-half {
  .slick-list {
   
    .slick-track {
      left: 5vw;
      margin-left: 0;
    }
  }
  .slick-slide > div {
    padding: 0 2rem 0 0;
  }
}

//survey-slider
.survey-slider{
  .slick-list {
    margin: 0 -18px;
  }
  .slick-slide > div {
    padding: 0 18px;
}
}

/* the parent */
.slick-list {
  margin: 0 -5px;
}


// when one slides height increases then all slides must adapt it

.slick-slide > div { height: 80%; }

.slick-track
{
    display: flex !important;
}
.slick-slide
{
    height: inherit !important;
}
.card-type-3{
  height: 15.3rem;
}
.document-scroller{
  overflow-x: scroll;
  overflow-y: hidden;
  margin-right: 1rem;
  margin-left: 1rem;
}

.adContainer{
  width: 90%;
  height: 322px;
  border-radius:  3rem;
  align-content: center;
  margin-left: 2rem;
}
.intro {
  .slick-dots{
  bottom: 3rem;
  margin-left: 0.5rem;
  }
  .slick-dots li{
    width: 14px;
  }
.slick-dots li.slick-active button:before, .slick-dots li button:before {
  color: $white;
  opacity: 1;
}
.slick-dots li.slick-active button {
  border: 1px solid $darkOrange;
  border-radius: 50%;
}
.slick-dots li button {
  border-radius: 50%;
  border: 1px solid $darkOrange;
  pointer-events: none;
  width: 1rem;
  height: 1rem;
  margin: 0.5rem;
}
.slick-dots .slick-active button{
  background: $darkOrange;
}
.slick-dots li.slick-active button:before {
  color: transparent;
}
}
.card-type-4{
  height: auto;
}
