$white: #ffffff;
$color_1: #383838;
$porpoiseGray: #585858;
$approxGray: #787878;
$greaseBlack: #161616;
$snowGray: #e6e6e6;
$coralGray: #68686b;
$blackishBlue: #171f2d;
$oxfordBlue: #223350;
$ravenBlack: rgba(0, 0, 0, 0.25);
$metalGray: rgba(202, 202, 202, 0.25);
$cloudGray: #eae8e8;
$frenchGray: #a1a1a1;
$dolphinGray: #dadada;
$fogGray: #f0f0f0;
$grandmaGray: #c5c5c5;
$garageMixWhite: #f8f8f8;
$red: #ee3330;
$maroon: #660014;
$darkGray: #807e7e;
$prussianBlue: #243551;
$darkSkyBlue: #243551;
$slateBlue: #5551b2;
$plankGray: #8e8e8e;
$gainsboro: #dcdcdc;
$dimGray: #707070;
$veryDimGray: #6c6c6c;
$aliceBlue: #f2f6fa;
$dewWhite: #f9fff9;
$peaGreen: #489a40;
$chineseSilver: #cdcdcd;
$fauxSapGreen: #508719;
$lightGray: #eeeeee;
$berryBlue: rgba(17, 56, 247, 0.24);
$appleGreen: #ebffd7;
$pearlSilver: #fafafa;
$sonicSilver: #767676;
$sonicSilver2: #727272;
$whiteSmoke: #f5f5f5;
$spanishGray: #9a9a9a;
$leatherBlack: #0d0d0d;
$fordGray: #979797;
$suvaGray: #919191;
$darkGrayishCyan: #2c3131;
$seaShell: #fef9f5;
$gordansGreen: #343534;
$deepRed: #c51717;
$silverGray: rgba(204, 204, 204, 0.18);
$almond: #eff5ff;
$mercury: #e7e7e7;
$soot: rgba(56, 56, 56, 0.1);
$skyBlue: rgba(3, 83, 151, 0.15);
$litesky: linear-gradient(179.92deg, #BCE0FF 0.07%, #FFFFFF 20.06%);
$bluewood: #2a4254;

// button
$lightWhite: #d9d9d9;
$black: rgba(0, 0, 0, 0.15);
$darkOrange: #e8630a;
$turquoiseBlue: #2bb792;
$parrotGreen: #21aa54;
$lavaGray: #a8a8a8;
$electricblue:#035397;
// category cards
//categoryCard1-green
$teaGreen: #f0ffda;
$forestGreen: #587e1d;
$greenLizard: #bee188;
$sheenGreen: rgba(109, 142, 58, 0.47);
// scheme card-green
$mulberry: #99c753;
$paleLavender: #ecffd0;
//categoryCard2-orange
$provincialPink: #fef8f1;
$carrotOrange: #f3ad58;
$rustOrange: #a46e2c;
$clayOrange: rgba(193, 128, 48, 0.3);
//scheme card-orange
$darkOrchid: #d4902b;
$apricot: #f8e6ce;
//categoryCard3-lightblue
$celesteBlue: #f3f9ff;
$cyanBlue: #9adcff;
$sapphireBlue: #0f5d9e;
$darkBlue: rgba(15, 93, 158, 0.3);
//scheme card-lightblue
$brilliantAzure: #36a1f8;
$veryLightBlue: #e1edf8;
//categoryCard4-purple
$magnolia: #f7f5ff;
$mauve: #b099f9;
$iris: #6c5d9b;
$deepPurple: rgba(92, 66, 174, 0.28);
$cobaltBlue: #3961e9;
//scheme card-purple
$lavender: #f0eff5;
//categoryCard5-pink
$blushPink: #fff0f5;
$balletPink: #faafc7;
$magenta: #a94e6b;
$darkPink: rgba(105, 11, 41, 0.3);
//categoryCard6-brown
$eggShell: #fff9e3;
$barleywhite: #F9EEC8;
$honeyCrisp: #bfad6c;
$mustard: #826f2d;
$brown: rgba(117, 95, 16, 0.3);
//categoryCard7-red
$amour: #ffeff0;
$fuchsiaBlush: #e8898e;
$rufous: #9c3036;
$burgundy: rgba(128, 30, 35, 0.3);
//categoryCard8-darkblue
$paleBlue: #e8f2ff;
$denimBlue: rgba(15, 93, 158, 0.24);
$egyptianBlue: #2e4ea2;
$cornFlowerBlue: #8ba7f0;
$lightCyan: #f1f6ff;

// schemecard
$grey: #757474;
$pink: #eda6bd;
$lightPink: #f5ecef;
$opalWhite: #fff9fa;

//servicecard
$daisyWhite: #f1f7ff;
$royalBlue: #4d72d0;
$navyBlue: rgba(77, 114, 208, 0.2);
$slateGray: rgba(198, 198, 198, 0.46);

// jobcard
$shamrockGreen: #438909;
$mintGreen: rgba(67, 137, 9, 0.14);
$pearlWhite: #eff7e7;
$charcoalBlack: #4a4a4a;
//tab
$grayishWhite: #f6f9fe;
$bluishWhite: #c4cfee;
//horizontal row
$gray: #d7d7d7;
//profile
$coinGray: rgba(202, 202, 202, 0.48);
$leadGray: #494949;
//footer
$crowBlack: rgba(0, 0, 0, 0.0864);

//scrollbar
$shadowGray: #828282;
$tealBlue: #035397;
//tab
$lightOrange: #fff6ee;
//popups
$dodgerBlue: #3c76f7;
$transparent: rgba(255, 255, 255, 0.2);
$transparent2: rgba(255, 255, 255, 0.5);
$paleWhite: #f2f2f2;
$flintGray: #717171;
//profile bar
$peach: #fff7f1;
//location card
$pewterGray: #a3a3a3;

$productColor: $tealBlue;
$tabColor: $lightOrange;
$productColor2: $darkOrange;
$error: $deepRed;

//color palette
$colors: (
  "white": $white,
  "color_1": $color_1,
  "porpoiseGray": $porpoiseGray,
  "approxGray": $approxGray,
  "greaseBlack": $greaseBlack,
  "snowGray": $snowGray,
  "coralGray": $coralGray,
  "blackishBlue": $blackishBlue,
  "oxfordBlue": $oxfordBlue,
  "ravenBlack": $ravenBlack,
  "metalGray": $metalGray,
  "cloudGray": $cloudGray,
  "frenchGray": $frenchGray,
  "dolphinGray": $dolphinGray,
  "fogGray": $fogGray,
  "grandmaGray": $grandmaGray,
  "garageMixWhite": $garageMixWhite,
  "red": $red,
  "maroon": $maroon,
  "darkGray": $darkGray,
  "prussianBlue": $prussianBlue,
  "slateBlue": $slateBlue,
  "plankGray": $plankGray,
  "gainsboro": $gainsboro,
  "dimGray": $dimGray,
  "veryDimGray": $veryDimGray,
  "aliceBlue": $aliceBlue,
  "dewWhite": $dewWhite,
  "peaGreen": $peaGreen,
  "chineseSilver": $chineseSilver,
  "fauxSapGreen": $fauxSapGreen,
  "lightGray": $lightGray,
  "appleGreen": $appleGreen,
  "pearlSilver": $pearlSilver,
  "sonicSilver": $sonicSilver,
  "sonicSilver2": $sonicSilver2,
  "whiteSmoke": $whiteSmoke,
  "spanishGray": $spanishGray,
  "suvaGray": $suvaGray,
  "darkGrayishCyan": $darkGrayishCyan,
  "sesShell": $seaShell,
  "gordansGreen": $gordansGreen,
  "deepRed": $deepRed,
  "silverGray": $silverGray,
  "almond": $almond,
  "mercury": $mercury,
  "soot": $soot,
  "skyBlue": $skyBlue,
  // button
  "lightWhite": $lightWhite,
  "black": $black,
  "turquoiseBlue": $turquoiseBlue,
  "parrotGreen": $parrotGreen,
  "lavaGray": $lavaGray,
  "electricblue": $electricblue,
  "litesky": $litesky,

  // category
  // category cards
  //categoryCard1-green
  "teaGreen": $teaGreen,
  "greenLizard": $greenLizard,
  "forestGreen": $forestGreen,
  "sheenGreen": $sheenGreen,
  // scheme card-green
  "mulberry": $mulberry,
  "paleLavender": $paleLavender,
  //categoryCard2-orange
  "provincialPink": $provincialPink,
  "carrotOrange": $carrotOrange,
  "rustOrange": $rustOrange,
  "clayOrange": $clayOrange,
  //scheme card-orange
  "darkOrchid": $darkOrchid,
  "apricot": $apricot,
  //categoryCard3-lightblue
  "celesteBlue": $celesteBlue,
  "cyanBlue": $cyanBlue,
  "sapphireBlue": $sapphireBlue,
  "darkBlue": $darkBlue,
  //scheme card-lightblue
  "brilliantAzure": $brilliantAzure,
  "veryLightBlue": $veryLightBlue,
  //categoryCard4-purple
  "magnolia": $magnolia,
  "mauve": $mauve,
  "iris": $iris,
  "deepPurple": $deepPurple,
  //scheme card-purple
  "lavender": $lavender,
  //categoryCard5-pink
  "blushPink": $blushPink,
  "balletPink": $balletPink,
  "magenta": $magenta,
  "darkPink": $darkPink,
  //categoryCard6-brown
  "eggShell": $eggShell,
  "honeyCrisp": $honeyCrisp,
  "mustard": $mustard,
  "brown": $brown,
  //categoryCard7-red
  "amour": $amour,
  "fuchsiaBlush": $fuchsiaBlush,
  "rufous": $rufous,
  "burgundy": $burgundy,
  //categoryCard8-darkblue
  "paleBlue": $paleBlue,
  "denimBlue": $denimBlue,
  "egyptianBlue": $egyptianBlue,
  "cornFloweeBlue": $cornFlowerBlue,
  "lightCyan": $lightCyan,
  "cobaltBlue": $cobaltBlue,

  // schemecard
  "grey": $grey,
  "pink": $pink,
  "lightPink": $lightPink,
  "opalWhite": $opalWhite,
  //servicecard
  "daisyWhite": $daisyWhite,
  "royalBlue": $royalBlue,
  "navyBlue": $navyBlue,
  "slateGray": $slateGray,

  // jobcard
  "shamrockGreen": $shamrockGreen,
  "mintGreen": $mintGreen,
  "pearlWhite": $pearlWhite,
  "charcoalBlack": $charcoalBlack,
  //tab
  "grayishWhite": $grayishWhite,
  "bluishWhite": $bluishWhite,
  //horizontal row
  "gray": $gray,
  //profile
  "coinGray": $coinGray,
  "leadGray": $leadGray,
  //footer
  "crowBlack": $crowBlack,

  //scrollbar
  "shadowGray": $shadowGray,
  //popups
  "dodgerBlue": $dodgerBlue,
  "transparent": $transparent,
  "paleWhite": $paleWhite,
  "flintGray": $flintGray,
  //profile bar
  "peach": $peach,
  //location card
  "pewterGray": $pewterGray,

  "productColor": $productColor,
  "productColor2": $productColor2,
  "tabColor": $tabColor,
  "error": $error,
  "darkSkyBlue": $darkSkyBlue,
);

// creates text and background color classes
@each $key, $val in $colors {
  .text-#{$key} {
    color: $val;
  }
  .bg-#{$key} {
    background-color: $val;
  }
}

//for colors with opacity-it converts hex to rgba color with opacity
@mixin background-opacity($color, $opacity) {
  background: $color;
  background: rgba($color, $opacity);
}
@mixin boxshadow-opacity($color, $opacity) {
  $colorr: rgba($color, $opacity);
  box-shadow: 0 0 1.1rem 0.4rem $colorr;
}

@mixin logoshadow-opacity($color, $opacity) {
  $colorr: rgba($color, $opacity);
  box-shadow: 0 0.2rem 0.4rem $colorr;
}
