/*common scss files to be imported*/

@import "./scss/font";
@import "./scss/color";
@import "./scss/cardscolor";
@import "./scss/common";
@import "./scss/utility";
@import "./scss/mixin";

@import "./scss/search";
@import "./scss/input";
@import "./scss/card";
@import "./scss/list";
@import "./scss/box";
@import "./scss/button";
@import "./scss/text";
@import "./scss/header";
@import "./scss/footer";
@import "./scss/slider";
@import "./scss/misc";
@import "./scss/wrapper";
@import "./scss/tab";
@import "./scss/scrollbar";
@import "./scss/popups";
@import "./scss/bar";
@import "./scss/accordian";
@import "./scss/layout";
@import "./scss/overlay";
@import "./scss/animation";
/*note:import responsive file at the end*/
@import "./scss/responsive";

@media (max-width: 599.99px) and (min-width: 320px) {
  html {
    font-size: 62.5%;
  }
}
@media (max-width: 768px) and (min-width: 600px) {
  html {
    font-size: 72.5%;
  }
}
