/*1rem=10px*/

/*footer*/
.footer {
  @include flexbox(space-evenly, center);
  width: 100vw;
  padding: 1.5rem 0;
  background-color: $white;
  position: fixed;
  bottom: 0;
  right: 0;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
      height: 2.9rem;
      width: 2.9rem;
      margin-bottom: .5rem;
    }
    .footer-text{
      @include font($font-xxs,$font-weight-medium);
      @include alignment(1.2rem,.0153846rem);
      color:$suvaGray;
      padding-bottom: .3rem;
    }
    .footer-text-active{
      font-weight: $font-weight-bold;
      color: $productColor;
    }
  }
  }

  .footer-space{
    width: 4rem;
  }
