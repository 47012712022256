/*1rem=10px*/
/*Scheme tab-benefit page*/
.react-tabs__tab--selected {
  &:focus-visible {
    outline: none;
  }
}
#tab-type-1 {
  @include flexbox(space-around, center);
  margin-bottom: 2.4rem !important;
  height: 5rem;
  background: $tabColor;
  border-radius: 3.4rem;
  border-bottom: none !important;
  position: relative;
  border: none;
}
/*Jobs tab-benefit page*/
#tab-type-2 {
  @extend #tab-type-1;
}
/*Service tab-benefit page*/
#tab-type-3 {
  @extend #tab-type-1;
  justify-content: space-evenly;
}
#tab-type-1,
#tab-type-3 {
  .react-tabs__tab--selected {
    position: relative;
    margin-top: -3.5rem;
    background: none !important;
    border: none !important;
  }
}
.react-tabs__tab--selected,
.react-tabs__tab {
  transition: all 0.5s linear;
}
.react-tabs__tab {
  border: none !important;
}

/*My schemes tab-when 3 or 4*/
#tab-type-4 {
  @include flexbox(space-around, center);
  background-color: $fogGray;
  padding-top: 2rem;
  position: fixed;
  width: 100%;
  z-index: 2;
  border-bottom: 0.1rem solid $dolphinGray;
  line-height: 1.2;
  overflow: scroll;
  .react-tabs__tab {
    @include font($font-xl, $font-weight-regular);
    list-style: none;
    position: relative;
    bottom: 0;
    text-align: center;
    color: $frenchGray;
    // padding: 0 .8rem;
    &:focus {
      outline: none;
    }
    p {
      padding-bottom: 1rem;
      white-space: nowrap;
    }
  }
  .react-tabs__tab {
    &:nth-of-type(4):before {
      content: url("../scssImgs/mySchemes/enrolled.svg");
    }
    &:nth-of-type(3):before {
      content: url("../scssImgs/mySchemes/applied.svg");
    }
    &:nth-of-type(2):before {
      content: url("../scssImgs/mySchemes/draft.svg");
    }
    &:nth-of-type(1):before {
      content: url("../scssImgs/mySchemes/saved.svg");
    }
  }

  .react-tabs__tab--selected {
    background: #f0f0f0  !important;
    @include font($font-xl, $font-weight-regular);
    border-color: none;
    color: $productColor2;
    &:nth-of-type(4)::before {
      content: url("../scssImgs/mySchemes/enrolled-active.svg");
    }
    &:nth-of-type(3)::before {
      content: url("../scssImgs/mySchemes/applied-active.svg");
    }
    &:nth-of-type(2)::before {
      content: url("../scssImgs/mySchemes/draft-active.svg");
    }
    &:nth-of-type(1)::before {
      content: url("../scssImgs/mySchemes/saved-active.svg");
    }
    div {
      border-bottom: 0.4rem solid $productColor2;
      border-radius: 4px 4px 0 0;
    }
  }
}
.ml #tab-type-4,.ta #tab-type-4,.te #tab-type-4{
  justify-content: space-between;
  .react-tabs__tab{
    padding: 0 1.2rem;
  }
}
/*My schemes tab-when 2*/
#tab-type-5 {
  @extend #tab-type-4;
  justify-content: space-evenly;
}
