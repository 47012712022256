/*1rem=10px*/
.hr-gray {
  border-bottom: 0.1rem solid $gray;
}
.divider {
  height: 0.8rem;
  background: $garageMixWhite;
  width: 100vw;
}

//review survey & manage profile & home page
.blur {
  filter: blur(3px);
}

/*progress bar in survey screen*/
.progress-bar {
  height: 0.4rem;
  width: 90vw;
  background-color: $gainsboro;
  img {
    top: -0.8rem;
    transition:left 1s linear;
  }
}
.progress-bar-fill {
  height: 100%;
  background-color: $productColor2;
  transition: width 1s linear;
}
.rocket {
  width: 2rem;
  background: $white;
  rotate: 45deg;
}
.checkbox {
  border: 0.1rem solid $oxfordBlue;
  border-radius: 0.2rem;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  max-width: 1.51rem;
  max-height: 1.51rem;
  min-height: 1.5rem;
}

/*snackbar*/
.snackbar {
  width: 90vw;
  background: $appleGreen;
  height: 4.5rem;
  position: fixed;
  bottom: 11.8rem;
}
.snackbar-benefit{
  @extend .snackbar;
  bottom: 18.8rem;
}
.__react_component_tooltip {
  @include font($font-xs, $font-weight-medium);
  font-size: 1.2rem !important;
  background: $leatherBlack !important;
  border: 0.1rem solid $fordGray !important;
  box-shadow: 0 0.2rem 0.7rem $gainsboro !important;
  color: $white !important;
  border-radius: 0.4rem !important;
  padding: 1.655rem 0 1.245rem 0.8rem !important;
  line-height: 1.8rem;
}
.__react_component_tooltip.show{
  opacity: 1!important;
  left: 0;
  right: 0;
  max-width: 50vw;
  margin-top: 5px!important;
}
.tooltip-text {
  font-weight: $font-weight-medium;
  font-size: $font-xs;
  line-height: 2.2rem;
  letter-spacing: -0.0469134px;
  color:$white;
}
.tooltip-icon-blue{
  height: 12px;
}
.group-tooltip-icon{
  margin-top: -3px;
  right: 2rem;
  position: relative;
}
.data-tool{
  display: inline-block;
  vertical-align: bottom;
  z-index: 2;
  position: relative;
}
.width-1 {
  max-width: 50vw;
}

//highlight in search screen
.highlight-search {
  // font-size: $font-md;
  color: $frenchGray;
  background-color: transparent;
}
//highlight in tooltip
.highlight-tooltip {
  @extend .tooltip-text;
  background-color: $productColor2;
}
//count for notifications
.count {
  @include flexbox(center, center);
  width: 2rem;
  height: 2rem;
  background-color: $productColor2;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  right: -8px;
}
//when menu is open
.select-2__control--menu-is-open {
  filter: blur(0px) !important;
  .select-2__menu {
    filter: blur(0px) !important;
  }
}

//inactive radio button
.radio-veryDimGray {
  border: 2px solid $white;
  box-shadow: 0 0 0 1px $dimGray;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: $white;
  transition: all ease-in 0.2s;
  .radio-veryDimGray:checked {
    background-color: $dimGray;
  }
}
//active radio button
.radio-productColor2 {
  @extend .radio-veryDimGray;
  box-shadow: 0 0 0 1px $productColor2;
}
.radio-productColor2:checked {
  background-color: $productColor2;
}
.z-99999{
  z-index: 99999;
}
//word break
.break-word{
  word-break: break-word;
}
//overflow
.overflow-h{
  overflow: hidden;
}

.mb-1{
  margin-bottom: 1.4rem;
}

//for selectTick Image
.tick-image {
  @include flexbox(center, center);
  position: absolute;
  width: 90px;
  right: 142px;
  top: 24.88%;
  bottom: 64.04%;
  margin-left: auto;
  margin-right: auto;
  background: #45DB81;
  }
   /* Thank You */
  .thankyou-text{
  position: absolute;
  top: 41.38%;
  bottom: 54.93%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 30px;
  /* identical to box height, or 94% */
  text-align: center;
  color: #4C5970;
  }
  /*Payment status test*/
  .payment-status-text{
    position: absolute;
    top: 41.38%;
    bottom: 54.93%;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 30px;
    /* identical to box height, or 94% */
    text-align: center;
    color: #4C5970;
    }
   /* <Your application has been successfully submitted> */
  .text-al{
  position: absolute;
  left: 5.87%;
  right: 6.13%;
  top: 49.75%;
  bottom: 42.86%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  /* or 136% */
  text-align: center;
  color: #4C5970;
  }
  .mt-45{
    margin-top: 45rem;
  }
  /* animation_500_l4fjnoos 1 */
  .animation_500 {
    @include flexbox(center, center);
    position: absolute;
    width: 25px;
    height: 25px; 
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .rectangle {
    position: absolute;
  width: 275px;
  height: 45px;
  left: 50px;
  top: 558px;
  background: #EBFFD7;
  border-radius: 4px;
  }
  
  .snackbar-thankyou {
    width: 90vw;
    background: $appleGreen;
    height: 4.5rem;
    position: fixed;
    bottom: 17.8rem;
  }

  /*Introduction cross css start*/ 
  .intro-cross{
    position: absolute;
    top: 1.7rem;
    right: 1rem;
    background: $brilliantAzure;
    opacity: 0.4;
    border-radius: 20px;
    padding: 4px 4px 4px 4px;
    margin: 10px;
  }
  /*Introduction cross css end*/ 
    //application status page
    .statusBox {
      display: flex;
      flex-wrap: wrap;
    }
    .date {
      flex-basis: 20%;
    }
    .img {
      flex-basis: 10%;
    }
    .status {
      flex-basis: 70%;
      overflow: hidden;
      white-space: pre-wrap;
      text-overflow: ellipsis;
      margin-top: 3px;
    }
    .tooltip-icon-intentscreen
    {
        margin-top: 0px;
        height: 15px;
        position: absolute;
        right: 16px;
        top: 13px;
        cursor: pointer;
        z-index: 1;
    }
    .verticalProgress{
      height: 30px;
      width: 2px;
      margin-left: 22%;
      transition: all 800ms ease;
    }
    .green{ background-color: #4BAE4F; }
    .blue{ background-color: #3961E9; }
    .red{ color: #EE3330; }
    .grey{ background-color:#BEBEBE}
    .p-9{
      padding-left: 2.5vw;
      padding-right: 2.5vw;
    }
    .stage-Status{
      color:$oxfordBlue !important;
    }
    .width-icon{
      width: 17.56px;
    }
    .profileIcon{
      width: 24px;
      height: 24px;
    }
    .card-type-5 {
      span::first-letter {
        text-transform: uppercase;
        display: flow-root !important;
      }
    }
    
    .button-view{
      margin-top: 50vh;
      margin-left: 3.5vw;
    }

    .invalid-otp-error-icon
  {
    float: left;
    width: 13px;
    position: relative;
    top: 4px;
    margin-right: 4px;
  }
  .otp-error-icon
  {
    float: left;
    width: 65px;
    position: relative;
    top: 5px;
    left: -6px;
  }
  .tooltip-icon-intentscreen
  {
      margin-top: 0px;
      height: 15px;
      position: absolute;
      right: 16px;
      top: 13px;
      cursor: pointer;
      z-index: 1;
  }
.status-img
{
  margin-left: -2px;
}
.green-status-image
{
  margin-top: -6px;
}
    .width-icon{
      width: 17.56px;
    }
    .profileIcon{
      width: 24px;
      height: 24px;
    }
    .card-type-5 {
      span::first-letter {
        text-transform: uppercase;
        display: flow-root !important;
      }
    }

.popover {
  position: relative;
  width: 250px !important;
  padding: 4px;
  text-align: left;
  white-space: normal;
  background-color: $bluewood !important;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: 0px !important;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  animation: fadeIn 3s;
      
      animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { visibility: visible; }
  to { visibility: hidden; }
}

.popover .arrow {
  display: visible;
}

.popover-text{
    background-color: $bluewood;
    height: 77px;
    width: 77px;
    color: white;
    cursor: pointer;
    float: right;
    
}
