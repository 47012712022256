/*1rem=10px*/
/*scheme-card*/
.card-type-3 {
  position: relative;
  width: 29.6rem;
  background: $white;
  border-radius: 0.4rem;
}
.card-type-3-header {
  @include flexbox(normal, center);
  min-height: 6.7rem;
  border-radius: 0.4rem;
  h3 {
    @include font($font-md, $font-weight-medium, $secondary-font);
    @include alignment(2rem, 0.05rem);
    // @include tripledottwo;
    // height: 3.3rem;
    color: $color_1;
    padding: 1.9rem 0.8rem 0.8rem 1.1rem;
  }
}
.card-type-3-footer {
  min-height: 8.6rem;
  p {
    color: $grey;
    @include alignment(2rem, 0.05rem);
    @include font($font-xxs, $font-weight-regular);
    padding: 0.7rem 0.9rem 2.2rem 1.2rem;
    @include tripledotthree;
  }
}
.card-type-3-logo {
  @include flexbox(center, center);
  position: absolute;
  left: 0.9rem;
  top: -1.9rem;
  width: 3.7rem;
  height: 3.7rem;
  border-radius: 50%;
  box-shadow: 0 0 .2rem $ravenBlack;
  img {
    height: 52%;
    width: 52%;
  }
}
.card-type-3-tag {
  position: absolute;
  right: -0.6rem;
  top: -0.3rem;
  background-color: $white;
  padding: 0.316rem 0.4rem 0.316rem 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0.5rem;
}

/*service-card*/
.card-type-2 {
  @extend .card-type-3;
  width: 14.1rem;
  border: 0.1rem solid $royalBlue;
  border-bottom: 0.4rem solid $royalBlue;
  box-shadow: 0 0.4rem 0.1rem $daisyWhite;
}
.card-type-2-header {
  @extend .card-type-3-header;
  background: $daisyWhite;
  // max-width: 14.3rem;
  border-radius: 0.3rem 0.3rem 0 0;
  line-height: 1.2rem;
  min-height: 3.6rem;
  h3 {
    @include tripledotone;
    font-size: $font-xs;
    line-height: 1.2rem;
    color: $color_1;
    padding-left: 0.7rem;
    padding-top: 1.6rem;
  }
}
.card-type-2-footer {
  min-height: 8.3rem;
  h3 {
    @include font($font-md, $font-weight-medium, $secondary-font);
    @include tripledottwo;
    height: 1.3rem;
    padding: 2.3rem 0 2.3rem 0.8rem;
    line-height: 1.8rem;
    color: $color_1; 
  }
}
.card-type-2-logo {
  @extend .card-type-3-logo;
  width: 2.6rem;
  height: 2.6rem;
  left: 0.7rem;
  top: -1.5rem;
}
/*job card*/
.card-type-4 {
  @extend .card-type-3;
  border-radius: 0.4rem;
  position: relative;
  width: 29.6rem;
}
.card-type-4-header {
  @extend .card-type-3-header;
  display: block;
  // width: 29.6rem;
  min-height: 8.4rem;
  background: $pearlWhite;
  border-radius: 0.4rem 0.4rem 0 0;
  .card-type-4-header-tag-container{
    @include tripledotone;
  }
  h3 {
    @include tripledotone;
    line-height: 0.2rem;
    color: $color_1;
    padding: 3.4rem 0 1.7rem 1.49rem;
  }
  span {
    @include font(1.05rem, $font-weight-regular);
    line-height: 2rem;
    padding: 0.4rem;
    border-radius: 0.7rem;
    margin-left: 0.5rem;
  }
  span:nth-of-type(1) {
    margin-left: 1.7rem;
  }
}
.card-type-4-footer {
  li {
    @include alignment(1.6rem, 0.05rem);
    @include font($font-xs, $font-weight-medium);
    list-style: none;
    color: $charcoalBlack;
  }
}
.card-type-4-logo {
  @extend .card-type-3-logo;
  left: 1.6rem;
}

/*location card*/
.card-type-5 {
  @include flexbox(normal, center);
  border: 0.51px solid $pewterGray;
  max-width: 90%;
  min-height: 9.4rem;
  max-height: 8.926rem;
  border-radius: 0.514rem;
  position: relative;
  span {
    @include alignment(1.6rem, 0.043rem);
    @include font($font-xs, $font-weight-regular);
    padding: 0 0.9rem 0 0.942rem;
    width: 8.1rem;
    color: $color_1;
    text-transform: capitalize;
    word-break: break-word;
  }
  img {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
  }
}

/*when card-5 is active*/
.card-type-5-active {
  box-sizing: border-box;
  border: 1px solid $productColor2;
  border-bottom: 4px solid $productColor2;
  img {
    width: 1.5rem;
    height: 1.5rem;
  }
  span {
    color: $productColor2;
  }
}

// language card
.card-type-6 {
  @include flexbox(normal, center, column);
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 0.091rem solid $pewterGray;
  min-width: 10.3rem;
  min-height: 9.8rem;
  position: relative;
  img:nth-of-type(1) {
    margin-top: 0.9rem;
  }
  img:nth-of-type(2) {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
  }
  span:nth-of-type(1) {
    @include font($font-md, $font-weight-regular);
    margin-top: 0.7rem;
    color: $blackishBlue;
  }
  span:nth-of-type(2) {
    @include font($font-xs, $font-weight-regular);
    //position is used as different fonts have different spacings
    position: absolute;
    bottom: 0.8rem;
    color: $pewterGray;
  }
}
.card-type-6-1 {
  @extend .card-type-6;
  padding: 0.9rem;
}
// card-type-6 active
.card-type-6-active {
  box-sizing: border-box;
  border: 0.1rem solid $productColor2;
  border-bottom: 0.4rem solid $productColor2;
  img:nth-of-type(2) {
    width: 15px;
    height: 15px;
  }
  span:nth-of-type(2) {
    bottom: 0.4rem;
  }
}

/*CategoryCard*/
.card-type-1 {
  @include flexbox(normal, normal, column);
  // max-height: 13.1rem;
  min-height: 11.6rem;
  width: 7.8rem;
  border-radius: 1.4rem;
  padding: 1rem;
  position: relative;
  h3 {
    @include font($font-xs, $font-weight-medium, $secondary-font);
    @include tripledottwo;
    word-wrap: break-word;
    padding-top: 0.9rem;
  }
  p {
    @include font($font-xxs, $font-weight-regular);
    line-height: 1.3rem;
    position: absolute;
    bottom: 1.1rem;
    left: 0.8rem;
  }
}
.card-type-1-logo {
  @include flexbox(center, center);
  min-height: 5.1rem;
  border-radius: 0.8rem;
  img {
    height: 43%;
    width: 43%;
  }
}
.card-type-1-1 {
  @include flexbox(center, center, column);
  box-sizing: border-box;
  min-height: 13.4rem;
  display: flex !important;
  max-width: 8.7rem;
  padding: 0 1.9545rem;
  border-radius: 1.4rem;
  background: $white;
  box-shadow: 0 0.2rem 0.2rem 0.2rem $slateGray;
  h3 {
    line-height: 2.3rem;
    text-align: center;
    color: $cobaltBlue;
  }
}

//expanded card
.card-type-1-1-expand {
  @extend .card-type-1-1;
  min-height: 15.7rem;
}

// class to be used in my schemes,my services & my jobs
.card-expand {
  width: 89vw!important;
  //  z-index: -2; //if in future we get any issue uncomment this and check for myschemes component.
  p {
    padding-right: 0;
  }
}
.card-delete {
  position: absolute;
  top: -2.5rem;
  right: -0.9rem;
}
//scheme card-expanded
.card-type-3.card-expand {
  .card-type-3-header h3 {
    padding: 2.6rem 1.3rem 1.1rem 1.1rem;
  }
  .card-type-3-footer {
    min-height: 7.3rem;
    p {
      padding: 0.7rem 1.4rem 2.8rem 1.2rem;
    }
  }
}

//service-card-expanded
.card-type-2.card-expand .card-type-2-header {
  h3 {
    @include font($font-md, $font-weight-medium, $secondary-font);
    @include alignment(2rem, 0.05rem);
  }
}
.card-type-2.card-expand .card-type-2-footer {
  h3 {
    @include alignment(2rem, 0.05rem);
    @include font($font-xxs, $font-weight-regular);
    padding: 0.7rem 1.4rem 1.1rem 1.2rem;
    color: $grey;
  }
}
//job-card-expanded
.card-type-4.card-expand {
  .card-type-4-header {
    min-height: 7.4rem;
    h3 {
      padding: 3rem 0 1.5rem 1.3rem;
    }
    span:nth-of-type(1) {
      margin-left: 1.3rem;
    }
  }
  .card-type-4-logo {
    left: 0.8rem;
  }
  .card-type-4-footer-list {
    padding: 1.5rem 1.5rem 0.8rem 1.5rem;
  }
}

// search screen cards
.card-type-7 {
  border-radius: 0.4rem;
  height: 7.8rem;
  width: 85vw;
  position: relative;
  p:nth-of-type(1) {
    @include tripledottwo;
    line-height: 1.6rem;
  }
  .card-type-7-logo {
    @include flexbox(center, center);
    position: absolute;
    top: 1.4rem;
    left: -2.5rem;
    width: 4.4rem;
    height: 4.4rem;
    border-radius: 0.8rem;
    background-color: $white;
    img {
      width: 68%;
      height: 68%;
    }
  }
}
.containerAd {
  display:contents;
  width: 100vw;
  height: 200vh;
  margin-right: 5%;
  justify-content: center;
}
.adRow {
  background-color: white;
}
.adimg {
  width: 150px;
  height: 175px;
  border-radius: 2rem;
  margin-bottom: 1rem;
  margin-right: 5%;
  margin-top: 1rem;  
  margin-left: 5%; 
}

/*incase of benefit screen*/

.card-dots-container {
  position: absolute;
  top: 0.5rem;
  right: 0.1rem; 
}
.card-dots:after {
  content: "⠇";
  font-size: 23px;
  color: #035397;
}
/*drop-down*/
.dropdown-menu {
  z-index: 1000;
  list-style: none;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.dropdown-item {
  display: block;
  padding: 1.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #d8d8d8;
}

/* Service status container */
.status-container
{
  display: flex;
  border-bottom: 0.4px solid #DDDDDD;
  padding: 9px 0px 3px 1px!important;
}
.status-icon-container
{
  flex-grow: 0.1;
  position: relative;
  left: 10px;
  top: 6px;
}
.status-info-container
{
  flex-grow: 3;
}
.status-icon
{
  width: 20px;
}
/* End - Service status container */

/* Service card document container */
.card-document-container
{
  display: flex;
  border-bottom: 0.4px solid #DDDDDD;
  padding: 9px 0px 3px 1px!important;
}
.card-document-icon-container
{
  flex-grow: 0.1;
  position: relative;
  left: 10px;
  top: 6px;
}
.card-document-info-container
{
  flex-grow: 3;
  padding-left: 4px;
}
.card-status-info-text
{
  color: #223350 !important;
  font-size: 1.2rem !important;
  padding: 0.7rem 1.4rem 0.8rem 1.7rem !important;
}
.status-action
{
  font-weight: 600;
}
.card-document-info-text
{
  font-weight: 600 !important;
  color: #035397 !important;
  font-size: 1.2rem !important;
  padding: 0.7rem 1.4rem 0.8rem 1.7rem !important;
}
.card-document-icon
{
  width: 20px;
}
.info_icon
{
  position: absolute;
  right: 10px;
  top: 136px;
  width: 15px;
}
.tooltip-box
{
  display: inline-block;
  background-color: black;
  color: white;
  padding: 5px;
  position: absolute;
  right: 21px;
  bottom: -12px;
}

/* End - Service status container */

.containerAd {
  display:contents;
  width: 100vw;
  height: 200vh;
  margin-right: 5%;
  justify-content: center;
}
.adRow {
  background-color: white;
}
.adimg {
  width: 150px;
  height: 175px;
  border-radius: 2rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  margin-top: 1rem;  
  margin-left: 5%; 
}
