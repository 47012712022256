/*1rem=10px*/
.header {
  @include flexbox(flex-start, center);
  min-height: 5.9rem;
  background-color: $productColor;
  // width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  img {
    position: absolute;
    left: 1.6rem;
  }
}

/*incase of benefit screen*/
.place {
  position: fixed;
  z-index: 2;
  top: 1.5rem;
  right: 1.8rem;
}
.header-text {
    @include tripledotone;
}

/* Application Status Page Header */

.header-menu {
  position: fixed;
  z-index: 3;
  top: 5.5rem;
  right: 1.8rem;
  background: white;
  list-style-type: none;
}
.header-menu-button {
  position: fixed;
  z-index: 3;
  top: 1.5rem;
  right: 1.8rem;
}
.threedots:after {
  content: "⠇";
  font-size: 23px;
  color: white;
}
/*drop-down*/

.dropdown-menu {
  z-index: 1000;
  list-style: none;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dropdown-item {
  display: block;
  padding: 1.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #d8d8d8;
}

/* end */

.header-text {
    @include tripledotone;
}
